import { useState, useRef, useEffect } from 'react';
import classNames from 'classnames/bind';
import styles from './Search.module.scss';
import { ImSpinner } from 'react-icons/im';
import { BsFillXCircleFill, BsSearch } from 'react-icons/bs';
import HeadlessTippy from '@tippyjs/react/headless';
import useDebounce from '~/utils/hooks/useDebounce';
import { searchRequest } from '~/api/services/searchService';
import ResultItem from './ResultItem';

const cx = classNames.bind(styles);

function Search() {
    const [searchValue, setSearchValue] = useState('');
    const [searchResult, setSearchResult] = useState([]);
    const [showResult, setShowResult] = useState(false);
    const [loading, setLoading] = useState(false);

    const debouncedValue = useDebounce(searchValue, 800);

    const inputRef = useRef();

    useEffect(() => {
        if (!debouncedValue.trim()) {
            setSearchResult([]);
            return;
        }

        const fetchApi = async () => {
            setLoading(true);
            const result = await searchRequest(debouncedValue);
            setSearchResult(result);
            setLoading(false);
        };
        fetchApi();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedValue]);

    const handleChange = (e) => {
        const searchValue = e.target.value;
        if (!searchValue.startsWith(' ')) {
            setSearchValue(searchValue);
        }
    };

    const handleClear = () => {
        setSearchValue('');
        setSearchResult([]);
        inputRef.current.focus();
    };

    const handleHideResult = () => {
        setShowResult(false);
    };

    return (
        <div>
            <HeadlessTippy
                interactive
                visible={showResult && searchResult.length > 0}
                render={(attrs) => (
                    <div className={cx('search-result')} tabIndex="-1" {...attrs}>
                        <h4 className={cx('search-title')}>Search Result</h4>
                        {searchResult.length > 0 &&
                            searchResult.map((result) => (
                                <ResultItem
                                    key={result._id}
                                    type={result.type}
                                    name={result.name}
                                    createdAt={result.createdAt}
                                />
                            ))}
                    </div>
                )}
                onClickOutside={handleHideResult}
            >
                <div className={cx('search')}>
                    <input
                        ref={inputRef}
                        value={searchValue}
                        placeholder="Search file"
                        spellCheck={false}
                        onChange={handleChange}
                        onFocus={() => setShowResult(true)}
                    />
                    {!!searchValue && !loading && <BsFillXCircleFill className={cx('clear')} onClick={handleClear} />}
                    {loading && <ImSpinner className={cx('loading')} />}

                    <button
                        className={cx('search-btn')}
                        onMouseDown={(e) => {
                            e.preventDefault();
                        }}
                    >
                        <BsSearch />
                    </button>
                </div>
            </HeadlessTippy>
        </div>
    );
}

export default Search;
