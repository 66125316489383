import classNames from 'classnames/bind';
import styles from './Info.module.scss';
import Button from '~/components/common/Button/Button';

const cx = classNames.bind(styles);

function Info({ fields, data, onChange }) {
    const renderDatas = fields.map((field) => ({
        [field]: data.hasOwnProperty(field) ? data[field] : '',
    }));

    return (
        <div className={cx('info-wrapper')}>
            <div className={cx('info-groups')}>
                {renderDatas.map((data, index) => (
                    <div className={cx('info-container')} key={index}>
                        <p className={cx('info-title')}>
                            {Object.keys(data)[0].charAt(0).toUpperCase()}
                            {Object.keys(data)[0].slice(1)} :
                        </p>
                        <p className={cx('info-data')}>
                            {Object.values(data)[0].length > 0 ? Object.values(data)[0] : `.....................`}
                        </p>
                    </div>
                ))}
            </div>
            <div className={cx('btn-container')}>
                <Button className={cx('edit-btn')} primary onClick={onChange}>
                    Update
                </Button>
            </div>
        </div>
    );
}

export default Info;
