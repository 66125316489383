import classNames from 'classnames/bind';
import styles from './RecentFile.module.scss';
import FileItem from '../File';
import { useQuery } from 'react-query';
import fyndrive from '~/api/services/fyndrive';
import { Skeleton } from 'antd';

const cx = classNames.bind(styles);

function RecentFile() {
    const {
        data: fileData = { data: [] },
        isLoading: loadingFiles,
        isError: errorFiles,
        refetch: refetchFiles,
    } = useQuery(['fyndrive.getFiles'], ({ queryKey }) => fyndrive.getRecentFiles({ page: 1, limit: 10 }));

    const files = fileData.data;

    return (
        <div className={cx('wrapper')}>
            <div className={cx('inner')}>
                <div className={cx('header')}>
                    <p className={cx('title')}>{files.length > 0 ? 'Recent File' : 'About Fyndrive'}</p>
                </div>
                <div className={cx('container')}>
                    {files.length > 0 ? (
                        <>
                            {loadingFiles ? (
                                <Skeleton />
                            ) : errorFiles ? (
                                <span>Error</span>
                            ) : (
                                files.map((item) => (
                                    <div key={item._id} className={cx('file-item')}>
                                        <FileItem grid={false} refetchFiles={refetchFiles} file={item} />
                                    </div>
                                ))
                            )}
                        </>
                    ) : (
                        <p>Fyndrive Description</p>
                    )}
                </div>
            </div>
        </div>
    );
}

export default RecentFile;
