import classNames from 'classnames/bind';
import styles from './PinModal.module.scss';
import { useState } from 'react';
import { toast } from 'react-hot-toast';
import Modal from '../Modal';
import Button from '~/components/common/Button';
import OtpInput from './OtpInput';
import fyndrive from '~/api/services/fyndrive';

const cx = classNames.bind(styles);

function PinModal({ onSuccess, onClose }) {
    const [pin, setPin] = useState('');
    const onChange = (value) => setPin(value);
    const [loading, setLoading] = useState(false);

    const handleConfirm = async () => {
        try {
            setLoading(true);

            //if user has file encryption key, check if pin is correct
            const res = await fyndrive.checkEncryptionKey(pin);
            console.log(res)
            const isCorrect = res.result;
            if (!isCorrect) {
                throw new Error('Incorrect PIN');
            }
            toast.success('PIN verified successfully');
            await onSuccess(pin);
        } catch (e) {
            console.log(e);
            toast.error(e.message);
            setPin('');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal onClose={onClose}>
            <div className={cx('wrapper')}>
                <p className={cx('title')}>PIN verification</p>

                <OtpInput value={pin} valueLength={6} onChange={onChange} />
                <Button primary className={cx('confirm-btn')} onClick={handleConfirm} disabled={loading}>
                    {loading ? 'Confirming...' : 'Confirm'}
                </Button>
            </div>
        </Modal>
    );
}

export default PinModal;
