import classNames from 'classnames/bind';
import styles from './Home.module.scss';
import Chart from '~/components/Chart';
import RecentFile from '~/components/RecentFile';
import MyPackage from '~/components/MyPackage';
import Folder from '~/components/Folder';
import { useQuery } from 'react-query';
import { getUserDetail } from '~/api/services/authService';
import { useDispatch } from 'react-redux';

const cx = classNames.bind(styles);
function Home() {
    const dispatch = useDispatch();

    const {
        data: myInfo = {},
        isLoading: loadingInfo,
        isError: errorInfo,
        // refetch: refetchInfo,
    } = useQuery(['getUserDetail'], () => getUserDetail(dispatch));

    const { myPackage } = myInfo;

    return (
        <div className={cx('wrapper')}>
            <div className={cx('col-left')}>
                <Chart data={myPackage} loading={loadingInfo} error={errorInfo} />
                <Folder />
            </div>

            <div className={cx('col-right')}>
                <RecentFile />
                <MyPackage data={myPackage} loading={loadingInfo} error={errorInfo} />
            </div>
        </div>
    );
}

export default Home;
