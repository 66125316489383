import classNames from 'classnames/bind';
import styles from './Header.module.scss';
import Logo from '~/components/Logo';
import Search from '~/components/Search';
import HeaderActions from '~/components/HeaderActions';
import { useSelector } from 'react-redux';

const cx = classNames.bind(styles);
function Header() {
    const darkTheme = useSelector((state) => state.toggle.dark);

    return (
        <div className={cx('wrapper', darkTheme && 'dark-wrapper')}>
            <div className={cx('inner')}>
                <Logo to />

                <Search />

                <HeaderActions />
            </div>
        </div>
    );
}

export default Header;
