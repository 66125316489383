// import localStorage from 'redux-persist/es/storage';
import axios from 'axios';

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    withCredentials: true,
    credentials: 'same-origin',
    headers: {
        'Content-Type': 'application/json',
    },
});

axiosInstance.interceptors.request.use(function (config) {
    const token = 'Bearer ' + JSON.parse(localStorage.getItem('accessToken'));
    if (token) {
        config.headers.Authorization = token;
    }
    return config;
});

export const getRequest = async (url, options = {}) => {
    try {
        const response = await axiosInstance.get(url, options);
        if (response.status === 200) {
            if (response.data) return response.data;
            return { message: ' Request success!' };
        } else if (response.status === 401) {
            return { message: response.message || 'Failed to authenticate', status: 401 };
        } else if (response.status === 404) {
            return { message: response.message || 'Bad request', status: 404 };
        }
    } catch (error) {
        console.log(error);
        return;
    }
};

export const postRequest = async (url, data, options = {}) => {
    try {
        const response = await axiosInstance.post(url, data, options);
        if (response.status === 200) {
            if (response.data) return response.data;
            return { message: ' Request success!' };
        } else if (response.status === 401) {
            return { message: response.message || 'Failed to authenticate', status: 401 };
        } else if (response.status === 404) {
            return { message: response.message || 'Bad request', status: 404 };
        }
    } catch (error) {
        console.log(error);
        return;
    }
};

export const putRequest = async (url, data, options = {}) => {
    try {
        const response = await axiosInstance.put(url, data, options);
        if (response.status === 200) {
            if (response.data) return response.data;
            return { message: ' Request success!' };
        } else if (response.status === 401) {
            return { message: response.message || 'Failed to authenticate', status: 401 };
        } else if (response.status === 404) {
            return { message: response.message || 'Bad request', status: 404 };
        }
    } catch (error) {
        console.log(error);
        return;
    }
};

export const deleteRequest = async (url, options = {}) => {
    try {
        const response = await axiosInstance.delete(url, options);
        if (response.status === 200) {
            if (response.data) return response.data;
            return { message: ' Request success!' };
        } else if (response.status === 401) {
            return { message: response.message || 'Failed to authenticate', status: 401 };
        } else if (response.status === 404) {
            return { message: response.message || 'Bad request', status: 404 };
        }
    } catch (error) {
        console.log(error);
        return;
    }
};
