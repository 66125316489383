import classNames from 'classnames/bind';
import styles from './ResultItem.module.scss';
import convertTimestamp from '~/utils/func/convertTimestamp';
import FilterIcon from '~/components/common/FilterIcon';

const cx = classNames.bind(styles);

function ResultItem({ type, name, createdAt }) {
    return (
        <div className={cx('result-item')}>
            <div className={cx('file-icon')}>
                <FilterIcon type={type} />
            </div>
            <div className={cx('file-info')}>
                <p className={cx('file-name')}>{name}</p>
                <p className={cx('uploaded-at')}>{convertTimestamp(createdAt)}</p>
            </div>
        </div>
    );
}

export default ResultItem;
