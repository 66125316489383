import classNames from 'classnames/bind';
import styles from './Verify.module.scss';
import { useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { verifyAccount } from '~/api/services/authService';
import { publicPath } from '~/routes/path';
import { toast } from 'react-hot-toast';

const cx = classNames.bind(styles);

function Verify() {
    const [code, setCode] = useState('');
    const [success, setSuccess] = useState(false);
    const [searchParams] = useSearchParams();
    const email = searchParams.get('email');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleVerifyAccount = async (e) => {
        const verifyPromise = new Promise(async (resolve, reject) => {
            setLoading(true);
            e.preventDefault();
            const verifyData = {
                email,
                code: code,
            };

            const verifyResult = await verifyAccount(verifyData);

            if (!verifyResult) {
                reject();
                return;
            }
            setSuccess(true);
            //redirect to login page
            navigate(publicPath.login);
            setLoading(false);
            resolve();
        });
        await toast.promise(verifyPromise, {
            loading: 'Verifying...',
            success: 'Account verified successfully, Please login to continue',
            error: 'Invalid code',
        });
    };

    return (
        <section className={cx('register-container')}>
            <h1 className={cx('register-title')}> Email verification </h1>
            <p className={cx('sub-title')}>
                We've sent a verification code to your email. Please enter your code below to activate your fyndrive
                account
            </p>
            <form onSubmit={handleVerifyAccount} className={cx('register-form')}>
                <div className={cx('group')}>
                    <input type="text" autoFocus required onChange={(e) => setCode(e.target.value)} />
                    <span className={cx('highlight_form')}></span>
                    <span className={cx('bar_form')}></span>
                    <label>code</label>
                    <button type="submit" className={cx('submit__btn')} disabled={loading}>
                        Verify Account
                    </button>
                </div>
            </form>
            {success && <Link to={publicPath.login}>Back to Login</Link>}
        </section>
    );
}

export default Verify;
