import {
    DocIcon,
    FigmaIcon,
    PDFIcon,
    ExcelIcon,
    XDIcon,
    VideoIcon,
    MusicIcon,
    AudioIcon,
    ImageIcon,
    OtherIcon,
} from '../Icon/FileIcon';

function FilterIcon({ type, width, height }) {
    const listIcons = [
        {
            type: 'video',
            icon: <VideoIcon width={width} height={height} />,
            extensions: ['mp4', 'mkv', 'avi', 'mov', 'wmv', 'flv', 'webm'],
        },
        {
            type: 'mp3',
            icon: <MusicIcon width={width} height={height} />,
            extensions: ['mp3', 'wav', 'ogg', 'flac', 'aac'],
        },
        {
            type: 'audi',
            icon: <AudioIcon width={width} height={height} />,
            extensions: ['m4a', 'wma', 'aiff', 'alac', 'ape', 'dsd'],
        },
        {
            type: 'image',
            icon: <ImageIcon width={width} height={height} />,
            extensions: ['png', 'jpg', 'jpeg', 'gif', 'svg', 'webp', 'tiff', 'psd', 'raw'],
        },
        {
            type: 'document',
            icon: <DocIcon width={width} height={height} />,
            extensions: ['doc', 'docx', 'txt'],
        },
        {
            type: 'xls',
            icon: <ExcelIcon width={width} height={height} />,
            extensions: ['xls', 'xlsx', 'csv'],
        },
        {
            type: 'pdf',
            icon: <PDFIcon width={width} height={height} />,
            extensions: ['pdf'],
        },
        {
            type: 'figma',
            icon: <FigmaIcon width={width} height={height} />,
            extensions: ['fig'],
        },
        {
            type: 'xd',
            icon: <XDIcon width={width} height={height} />,
            extensions: ['xd'],
        }
    ]

    const icon = listIcons.find((icon) => icon.extensions.includes(type));
    return icon ? icon.icon : <OtherIcon width={width} height={height} />;

}

export default FilterIcon;
