import styles from './ResetPassword.module.scss';
import classNames from 'classnames/bind';
import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { resetPassword } from '~/api/services/authService';

const cx = classNames.bind(styles);

function ResetPassword() {
    const params = useParams();

    const [password, setPassword] = useState('');
    const [confrimPassword, setConfrimPassword] = useState('');
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);

    const handleSendMail = async (e) => {
        e.preventDefault();
        const sendData = {
            token: params.token,
            newPassword: password,
        };

        console.log(sendData);

        const resetPass = await resetPassword(sendData);
        if (!resetPass) {
            setError(true);
            return;
        }

        console.log({ resetPass });
        setSuccess(true);
    };

    return (
        <section className={cx('forgotPassword')}>
            <h1 className={cx('forgotPassword-title')}>Reset your password?</h1>
            <p className={cx('text_instruct')}>Enter new password.</p>
            <form className={cx('forgotPassword-form')} onSubmit={handleSendMail}>
                {/* Password */}
                <div className={cx('group')}>
                    <input
                        autoFocus
                        type="text"
                        required
                        value={password}
                        onChange={(e) => {
                            setError(false);
                            setPassword(e.target.value);
                        }}
                    />
                    <span className={cx('highlight_form')}></span>
                    <span className={cx('bar_form')}></span>
                    <label>Password</label>
                </div>
                {/* Confrim password */}
                <div className={cx('group')}>
                    <input
                        type="text"
                        required
                        value={confrimPassword}
                        onChange={(e) => {
                            setError(false);
                            setConfrimPassword(e.target.value);
                        }}
                    />
                    <span className={cx('highlight_form')}></span>
                    <span className={cx('bar_form')}></span>
                    <label>Confrim Password</label>
                </div>

                {error && <p className={cx('error')}>Error</p>}
                {success && <p className={cx('success')}>Reset success</p>}
                <button type="submit" className={cx('submit__btn', success && 'disabled')} disabled={success}>
                    Submit
                </button>
            </form>

            <div className={cx('to_create_account')}>
                <div className={cx('login-register')}>
                    <Link className={cx('login-register-link')} to="/login">
                        Back to Login
                    </Link>
                </div>
            </div>
        </section>
    );
}
export default ResetPassword;
