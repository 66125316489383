import { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import styles from './Folder.module.scss';
import { DocumentIcon, ImageIcon, VideoIcon, OtherIcon } from '../common/Icon/FolderIcons';
import { privatePath } from '~/routes/path';
import FolderItem from './FolderItem';
import { getFolderInfo } from '~/api/services/fileService';

const cx = classNames.bind(styles);

function Folder() {
    const [folders, setFolders] = useState([]);

    useEffect(() => {
        const fetchApi = async () => {
            const folderInfos = await getFolderInfo();

            folderInfos.forEach((folder) => {
                switch (true) {
                    case folder.title === 'Documents':
                        folder.icon = <DocumentIcon />;
                        folder.to = privatePath.document;
                        break;
                    case folder.title === 'Images':
                        folder.icon = <ImageIcon size={40} />;
                        folder.to = privatePath.image;
                        break;
                    case folder.title === 'Medias':
                        folder.icon = <VideoIcon />;
                        folder.to = privatePath.media;
                        break;
                    case folder.title === 'Others':
                        folder.icon = <OtherIcon />;
                        folder.to = privatePath.other;
                        break;
                    default:
                        break;
                }
            });

            setFolders(folderInfos);
        };
        fetchApi();
    }, []);

    return (
        <div className={cx('inner')}>
            {folders.length > 0 ? (
                folders.map((item, index) => (
                    <FolderItem
                        key={index}
                        storage={item.total}
                        title={item.title}
                        time={item.uploadedAt}
                        icon={item.icon}
                        to={item.to}
                    />
                ))
            ) : (
                <p>Loading ...</p>
            )}
        </div>
    );
}

export default Folder;
