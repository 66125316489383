import classNames from 'classnames/bind';
import styles from './RenameModal.module.scss';
import Modal from '../Modal';
import { useState } from 'react';
import Button from '~/components/common/Button';
import { changeFileName } from '~/api/services/fileService';
import { MdOutlineClear } from 'react-icons/md';

const cx = classNames.bind(styles);

function RenameModal({ _id, name, onClose, refetchFiles }) {
    const [username, setUsername] = useState('');
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);

    const handleChange = (e) => {
        setError(false);
        setUsername(e.target.value);

        if (username.length > 0) {
            setSuccess(true);
        }
    };

    const handleSave = async () => {
        const changeName = await changeFileName(_id, `${username}.${name.split('.').pop()}`);
        if (!changeName) {
            setError(true);
        }
        console.log('changeName >>> ', changeName);
        setSuccess(true);
        await refetchFiles();
        onClose();
    };

    return (
        <Modal onClose={onClose}>
            <div className={cx('wrapper')}>
                <div className={cx('header')}>
                    <p className={cx('title')}>Change File Name</p>
                    <MdOutlineClear className={cx('close')} onClick={onClose} />
                </div>

                <input
                    className={cx('input')}
                    value={username}
                    onChange={handleChange}
                    placeholder={name.split('.')[0]}
                    spellCheck={false}
                />

                {error && <p className={cx('error')}>Error</p>}
                <Button
                    className={cx('save-btn', !success && 'disabled')}
                    primary
                    onClick={handleSave}
                    disabled={!success}
                >
                    Save
                </Button>
            </div>
        </Modal>
    );
}

export default RenameModal;
