import classNames from 'classnames/bind';
import styles from './Sidebar.module.scss';
import { useSelector } from 'react-redux';
import DefaultMenu from '~/components/SidebarMenu/DefaultMenu';
import SubMenu from '~/components/SidebarMenu/SubMenu';
import { defaultItemData, moreItemData } from '~/utils/data/sidebarData';

const cx = classNames.bind(styles);
function Sidebar() {
    const navigate = useSelector((state) => state.navigate);

    return (
        <aside>
            {navigate.defaultItems && <DefaultMenu data={defaultItemData} />}
            {navigate.moreItems && <SubMenu data={moreItemData} />}
        </aside>
    );
}

export default Sidebar;
