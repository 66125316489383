import { postRequest, getRequest } from '../config';
import {
    loginStart,
    loginSuccess,
    loginFailed,
    logOutStart,
    logOutSuccess,
    logOutFailed,
    registerStart,
    registerSuccess,
    registerFailed,
    getMyInfoStart,
    getMyInfoSuccess,
    getMyInfoFailed,
} from '~/redux/authSlice';
import { privatePath, publicPath } from '~/routes/path';
import queryString from 'query-string';

export const getUserDetail = async (dispatch) => {
    try {
        dispatch(getMyInfoStart());
        const res = await getRequest('account/me');
        if (!res) {
            console.log('error >>> ', res);
            dispatch(getMyInfoFailed());
        } else {
            dispatch(getMyInfoSuccess(res));
            return res;
        }
    } catch (error) {
        console.log(error);
        dispatch(getMyInfoFailed());
    }
};

export const loginUser = async (data, dispatch, navigate) => {
    try {
        dispatch(loginStart());

        const loginUser = JSON.stringify(data);
        const res = await postRequest('account/login', loginUser);

        if (!res) {
            dispatch(loginFailed());
        } else {
            localStorage.setItem('accessToken', JSON.stringify(res.accessToken));
            localStorage.setItem('refreshToken', JSON.stringify(res.refreshToken));
            dispatch(loginSuccess(res.user));
            //get me
            await getUserDetail(dispatch);
            navigate(privatePath.home);
            return res;
        }
    } catch (error) {
        dispatch(loginFailed());
    }
};

export const logoutUser = async (dispatch) => {
    try {
        dispatch(logOutStart());
        dispatch(getMyInfoStart());

        dispatch(logOutSuccess());
        dispatch(getMyInfoSuccess(null));
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('myKey');
    } catch (error) {
        dispatch(logOutFailed());
        dispatch(getMyInfoFailed());
    }
};

export const registerUser = async (data, dispatch, navigate) => {
    try {
        dispatch(registerStart());

        const registerData = JSON.stringify(data);
        const res = await postRequest('account/create', registerData);

        console.log('register >>> ', res);

        if (!res) {
            dispatch(registerFailed());
        }

        dispatch(registerSuccess());
        // to /verify?email=...
        navigate({
            pathname: publicPath.verify,
            search: queryString.stringify({ email: res.email }),
        });
        return res;
    } catch (error) {
        dispatch(registerFailed());
    }
};

export const verifyAccount = async (data) => {
    try {
        const res = await postRequest('account/verify', data);
        console.log(res);
        if (!res) {
            console.log('verify failed');
        }

        console.log('verify success >>> ', res);
        localStorage.removeItem('registedEmail');
        return res;
    } catch (error) {
        console.log(error);
    }
};

export const sendMailWhenForgotPass = async (data) => {
    try {
        const res = await postRequest('account/pass/forgot/email', data);
        console.log('pass/forgot/email >>>', res);
        if (!res) {
            console.log('pass/forgot/email failed');
        }

        console.log('pass/forgot/email success >>> ', res);
        return res;
    } catch (error) {
        console.log(error);
    }
};

export const resetPassword = async (data) => {
    try {
        const res = await postRequest('account/reset/password', data);
        console.log('account/reset/password >>>', res);
        if (!res) {
            console.log('account/reset/password failed');
        }

        console.log('account/reset/password success >>> ', res);
        return res;
    } catch (error) {
        console.log(error);
    }
};
