import classNames from 'classnames/bind';
import styles from './Chart.module.scss';
import MyChart from './MyChart';
import convertByte from '~/utils/func/convertByte';
import useWindowSize from '~/hook/useWindowSize';
const cx = classNames.bind(styles);

function Chart({ data, loading, error }) {
    const used = data ? convertByte(data?.used) : '';

    const storage = data ? convertByte(data?.storage) : '';

    const percent = data ? +((data?.used / data?.storage) * 100).toFixed(2) : 0;

    const isTablet = useWindowSize(992)
    return (
        <div className={cx('wrapper')}>
            <div className={cx('inner')}>
                <MyChart percent={percent} size={230} label={"Space used"} />
                <div className={cx('description')}>
                    <p className={cx('title')}>Available Storage</p>
                    <p className={cx('sub-title')}>
                        {loading ? 'loading ...' : error ? 'error... ' : `${used} / ${storage}`}
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Chart;
