const convertByte = (byte) => {
    const units = ['bytes', 'KB', 'MB', 'GB', 'TB'];
    let i = 0;
    while (byte >= 1024 && i < units.length - 1) {
        byte /= 1024;
        i++;
    }
    return `${byte.toFixed(2)} ${units[i]}`;
};

export default convertByte;
