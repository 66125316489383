import classnames from 'classnames/bind';
import styles from './Graphic.module.scss';
import Logo from '../Logo';
import images from '~/assets/images';

const cx = classnames.bind(styles);

function Graphic() {
    return (
        <div className={cx('graphic-container')}>
            <div>
                <div className={cx('graphic_padding_page')}>
                    <Logo textColor connectLogo />
                </div>
                <div className={cx('content_text_graphic')}>
                    <h1 className={cx('title')}>Manage your files the best way</h1>
                    <p className={cx('sub-title')}>
                        Awesome, we have created the perfect place for you to store all your documents.
                    </p>
                </div>
            </div>

            <div className={cx('text-center')}>
                <img src={images.illustration} alt={images.illustration} className={cx('image')} />
            </div>
        </div>
    );
}

export default Graphic;
