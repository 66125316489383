import classNames from 'classnames/bind';
import styles from './UploadModal.module.scss';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setUploadModal } from '~/redux/modalSlice';
import { toast } from 'react-hot-toast';
import { Upload } from 'antd';
import Modal from '../Modal';
import Button from '~/components/common/Button';
import { GrAttachment } from 'react-icons/gr';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import { encryptFile, getCipherKey } from '~/service/encrypt';
import fyndrive from '~/api/services/fyndrive';
import getFolderNameByFileType from '~/utils/func/getFolderNameByType';
import checkLocation from '~/utils/func/checkLocation';
import { useNavigate } from 'react-router-dom';

const cx = classNames.bind(styles);

function UploadModal({ pin, setPin, refetchFiles }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [file, setFile] = useState(null);

    const handleChooseFile = ({ file }) => {
        setFile(file);
    };

    const handleUpload = async () => {
        const uploadPromise = new Promise(async (resolve, reject) => {
            try {
                if (!file || !pin) {
                    throw new Error('File or Pin is not valid');
                }
                //encrypt the file
                const me = await fyndrive.me();
                const fileEncryptionKey = me?.user?.fileEncryptionKey;
                if (!fileEncryptionKey) {
                    throw new Error('File Encryption Key is not valid');
                }
                const plainKey = await getCipherKey(pin, fileEncryptionKey);
                const encryptedFile = await encryptFile(plainKey, file);

                const getFolder = getFolderNameByFileType(file.type);

                const signedUrlData = await fyndrive.getSignedUrl({
                    files: [
                        {
                            name: file.name,
                            folder: getFolder,
                            type: file.name.split('.').pop(),
                            size: file.size,
                        },
                    ],
                });
                const signedUrl = signedUrlData[0]?.url;
                if (!signedUrl) {
                    throw new Error('Signed url is not valid');
                }

                console.log(signedUrl);
                //upload file
                const uploadResult = await fetch(signedUrl, {
                    method: 'PUT',
                    body: encryptedFile,
                });

                if (!uploadResult.ok) {
                    throw new Error('Upload failed');
                }

                const currentPath = `/${getFolder.toLocaleLowerCase()}`;
                const isChecked = checkLocation(currentPath);
                if (isChecked) {
                    navigate(currentPath);
                }

                await refetchFiles();
                resolve();
            } catch (e) {
                console.log(e);
                reject(e.message);
            } finally {
                dispatch(setUploadModal());
                setFile(null);
                setPin('');
            }
        });
        await toast.promise(uploadPromise, {
            loading: 'Uploading...',
            success: 'Upload successfully',
            error: 'Upload failed',
        });
    };

    const handleCancel = () => {
        if (!file) {
            dispatch(setUploadModal());
        }
        setFile(null);
    };

    return (
        <Modal>
            <div className={cx('wrapper')}>
                <div className={cx('header')}>
                    <h1 className={cx('title')}>Upload File</h1>
                </div>
                <Upload.Dragger
                    accept={['*']}
                    showUploadList={false}
                    customRequest={handleChooseFile}
                    className={cx('upload-dragger')}
                >
                    {file ? (
                        <div className={cx('file-input')}>
                            <span className={cx('file-name')}>
                                <GrAttachment style={{ marginRight: 10 }} size={18} />
                                {file.name}
                            </span>
                        </div>
                    ) : (
                        <div className={cx('file-input')}>
                            <p>Drag and drop file here or click to browse</p>
                            <Button leftIcon={<AiOutlineCloudUpload />} className={cx('choose-btn')}>
                                Choose File
                            </Button>
                        </div>
                    )}
                </Upload.Dragger>

                <div className={cx('btn-actions')}>
                    <Button className={cx('cancel-btn')} outline onClick={handleCancel}>
                        Cancel
                    </Button>
                    <Button className={cx('save-btn')} primary onClick={handleUpload}>
                        Upload
                    </Button>
                </div>
            </div>
        </Modal>
    );
}

export default UploadModal;
