import { getRequest, postRequest, deleteRequest } from '../config';

export const getAllFileByFolder = async (folder) => {
    try {
        const res = await getRequest('file?', {
            params: {
                folder,
            },
        });

        return res.data;
    } catch (error) {
        console.log(error);
    }
};

export const getFolderInfo = async () => {
    try {
        const res = await getRequest('file/info-all');

        return res;
    } catch (error) {
        console.log(error);
    }
};

export const changeFileName = async (id, name) => {
    try {
        const res = await postRequest(`file/update/${id}`, {
            newName: name,
        });

        console.log('change name >>> ', res);

        return res;
    } catch (error) {
        console.log(error);
    }
};

export const moveToTrash = async (id) => {
    try {
        const res = await getRequest(`file/delete/soft/${id}`);
        console.log('move to trash >>> ', res);
        return res;
    } catch (error) {
        console.log(error);
    }
};

export const putBackPrevFolder = async (id) => {
    try {
        const res = await getRequest(`file/put-back/${id}`);
        console.log('put back >>> ', res);
        return res;
    } catch (error) {
        console.log(error);
    }
};

export const removeFile = async (key) => {
    try {
        const res = await deleteRequest(`drive/delete/${key}`);
        console.log('remove file >>> ', res);
        return res;
    } catch (error) {
        console.log(error);
    }
};
