import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    defaultItems: true,
    moreItems: false,
};

const navigateSlice = createSlice({
    name: 'navigate',
    initialState,
    reducers: {
        setDefaultItems: (state) => {
            state.defaultItems = true;
            state.moreItems = false;
        },
        setMoreItems: (state) => {
            state.moreItems = true;
            state.defaultItems = false;
        },
    },
});

export const { setDefaultItems, setMoreItems } = navigateSlice.actions;

export default navigateSlice.reducer;
