import classNames from 'classnames/bind';
import styles from './Folder.module.scss';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import fyndrive from '~/api/services/fyndrive';
import FileItem from '~/components/File';
import { AiOutlineArrowUp } from 'react-icons/ai';
import { BsFillGridFill, BsList } from 'react-icons/bs';
import { Skeleton } from 'antd';

import { useDispatch, useSelector } from 'react-redux';
import { setPinModal, setUploadModal } from '~/redux/modalSlice';
import PinModal from '~/components/Modal/PinModal';
import UploadModal from '~/components/Modal/UploadModal';

const cx = classNames.bind(styles);

function Folder() {
    const location = useLocation();
    const fileType = location.pathname.split('/')[1];

    const dispatch = useDispatch();
    const { pinModal, uploadModal } = useSelector((state) => state.modal);

    const [grid, setGrid] = useState(true);
    const [pin, setPin] = useState('');

    const {
        data: fileData = { data: [] },
        isLoading: loadingFiles,
        isError: errorFiles,
        refetch: refetchFiles,
    } = useQuery(['fyndrive.getFilesByType', fileType], ({ queryKey }) =>
        fyndrive.getFilesByType(queryKey[1], { page: 1, limit: 15 }),
    );

    const files = fileData.data;

    const handleConfirmPinSuccess = async (pin) => {
        //close pin modal
        dispatch(setPinModal());
        //show upload modal
        dispatch(setUploadModal());
        setPin(pin);
    };

    return (
        <>
            <div className={cx('wrapper')}>
                {/* Title */}
                <p className={cx('folder-title')}>
                    {fileType.charAt(0).toUpperCase()}
                    {fileType.slice(1)}
                </p>

                {/* Actions */}
                <div className={cx('actions')}>
                    <p className={cx('size-total')}>Total: 128 GB</p>
                    <div className={cx('btn')}>
                        <button className={cx('sort-btn')}>
                            <AiOutlineArrowUp /> Name
                        </button>
                        <button className={cx('list-btn', !grid && 'active')} onClick={() => setGrid(false)}>
                            <BsList size={22} />
                        </button>
                        <button className={cx('grid-btn', grid && 'active')} onClick={() => setGrid(true)}>
                            <BsFillGridFill size={22} />
                        </button>
                    </div>
                </div>

                {/* Container */}
                <div className={cx('container', grid ? 'container-gird' : 'container-list')}>
                    {loadingFiles ? (
                        <Skeleton />
                    ) : errorFiles ? (
                        <p>Error</p>
                    ) : (
                        files.map((file) => (
                            <FileItem
                                file={file}
                                grid={grid}
                                key={file._id}
                                trash={fileType === 'trash'}
                                refetchFiles={refetchFiles}
                            />
                        ))
                    )}
                </div>
            </div>

            {/* Redux modal */}
            {pinModal && <PinModal onSuccess={handleConfirmPinSuccess} />}
            {uploadModal && <UploadModal pin={pin} setPin={setPin} refetchFiles={refetchFiles} />}
        </>
    );
}

export default Folder;
