export const DocumentIcon = () => (
    <>
        {/* Boxshadow */}
        <g opacity="0.4" filter="url(#filter0_f_517_11674)">
            <circle cx="47.2363" cy="48" r="33" fill="#FF7474" />
        </g>
        {/* background */}
        <circle cx="47.2363" cy="43" r="33" fill="#FF7474" />
        {/* Icon */}
        <path
            d="M57.149 32.3787C57.3253 32.6559 57.0232 32.9788 56.7028 32.906V32.906C56.0761 32.7193 55.3828 32.626 54.6761 32.626H50.3347C50.088 32.626 49.8556 32.51 49.7074 32.3127L48.2095 30.3193V30.3193C48.0216 30.0533 48.1992 29.666 48.5248 29.666H52.1961C54.2775 29.666 56.1109 30.747 57.149 32.3787Z"
            fill="white"
        />
        <path
            d="M58.089 35.7193C57.5157 35.306 56.8623 34.9993 56.1557 34.826C55.6757 34.6927 55.1823 34.626 54.6757 34.626H49.7157C48.9423 34.626 48.889 34.5593 48.4757 34.0127L46.609 31.5327C45.7423 30.3727 45.0623 29.666 42.889 29.666H39.7957C36.5423 29.666 33.9023 32.306 33.9023 35.5593V50.4393C33.9023 53.6927 36.5423 56.3327 39.7957 56.3327H54.6757C57.929 56.3327 60.569 53.6927 60.569 50.4393V40.5193C60.569 38.5327 59.5957 36.786 58.089 35.7193ZM50.4223 48.786H44.0357C43.5157 48.786 43.1157 48.3727 43.1157 47.8527C43.1157 47.346 43.5157 46.9193 44.0357 46.9193H50.4223C50.9423 46.9193 51.3557 47.346 51.3557 47.8527C51.3557 48.3727 50.9423 48.786 50.4223 48.786Z"
            fill="white"
        />
    </>
);

export const ImageIcon = ({ size }) => (
    <div style={{
        width: size,
        height: size,
        borderRadius: '50%',
        backgroundColor: '#56B8FF',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
    }}>
        <svg width={size / 2} height={size / 2} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M24.1509 3.38061C24.3273 3.65784 24.0251 3.98079 23.7047 3.90797C23.0781 3.7213 22.3847 3.62797 21.6781 3.62797H17.3366C17.0899 3.62797 16.8575 3.51192 16.7093 3.31466L15.2114 1.3213C15.0236 1.05523 15.2011 0.667969 15.5268 0.667969H19.1981C21.2794 0.667969 23.1128 1.74894 24.1509 3.38061Z" fill="white" />
            <path d="M25.091 6.7213C24.5176 6.30797 23.8643 6.0013 23.1576 5.82797C22.6776 5.69463 22.1843 5.62797 21.6776 5.62797H16.7176C15.9443 5.62797 15.891 5.5613 15.4776 5.01464L13.611 2.53464C12.7443 1.37464 12.0643 0.667969 9.89096 0.667969H6.79763C3.5443 0.667969 0.904297 3.30797 0.904297 6.5613V21.4413C0.904297 24.6946 3.5443 27.3346 6.79763 27.3346H21.6776C24.931 27.3346 27.571 24.6946 27.571 21.4413V11.5213C27.571 9.53464 26.5976 7.78797 25.091 6.7213ZM17.4243 19.788H11.0376C10.5176 19.788 10.1176 19.3746 10.1176 18.8546C10.1176 18.348 10.5176 17.9213 11.0376 17.9213H17.4243C17.9443 17.9213 18.3576 18.348 18.3576 18.8546C18.3576 19.3746 17.9443 19.788 17.4243 19.788Z" fill="white" />
        </svg>
    </div>
);

export const VideoIcon = () => (
    <>
        <g opacity="0.4" filter="url(#filter0_f_511_9966)">
            <circle cx="47.2363" cy="48" r="33" fill="#3DD9B3" />
        </g>
        <circle cx="47.2363" cy="43" r="33" fill="#3DD9B3" />
        <path
            d="M59.437 35.2273C58.8903 34.934 57.7436 34.6273 56.1836 35.7207L54.2236 37.1073C54.077 32.9607 52.277 31.334 47.9036 31.334H39.9036C35.3436 31.334 33.5703 33.1073 33.5703 37.6673V48.334C33.5703 51.4007 35.237 54.6673 39.9036 54.6673H47.9036C52.277 54.6673 54.077 53.0406 54.2236 48.894L56.1836 50.2806C57.0103 50.8673 57.7303 51.054 58.3036 51.054C58.797 51.054 59.1836 50.9073 59.437 50.774C59.9836 50.494 60.9036 49.734 60.9036 47.8273V38.174C60.9036 36.2673 59.9836 35.5073 59.437 35.2273ZM45.9036 42.174C44.5303 42.174 43.397 41.054 43.397 39.6673C43.397 38.2807 44.5303 37.1607 45.9036 37.1607C47.277 37.1607 48.4103 38.2807 48.4103 39.6673C48.4103 41.054 47.277 42.174 45.9036 42.174Z"
            fill="white"
        />
        <path
            d="M10.1325 131.681V113.104C9.32734 104.471 11.2976 87.3449 41.836 85.2126C69.8842 83.2541 75.8534 79.3007 90.5529 53.5299C102.653 32.3168 121.126 31.915 138.043 31.5471C140.259 31.4989 142.449 31.4513 144.594 31.3575C155.962 30.8606 191.067 30.9675 216.485 31.166C227.309 31.2505 236 40.0336 236 50.8243V223.286C236 234.174 227.146 243 216.225 243H29.775C18.8536 243 10 234.174 10 223.286V131.681H10.1325Z"
            fill="white"
        />
    </>
);

export const OtherIcon = () => (
    <>
        <g opacity="0.4" filter="url(#filter0_f_511_9967)">
            <circle cx="47.2363" cy="48" r="33" fill="#EEA8FD" />
        </g>
        <circle cx="47.2363" cy="43" r="33" fill="#EEA8FD" />
        <path
            d="M61.4155 35.5932C60.4769 32.4105 57.8222 29.7558 54.6394 28.8171C52.2194 28.1131 50.5474 28.1718 49.3887 29.0371C47.9953 30.0785 47.834 31.9559 47.834 33.2905V36.9426C47.834 40.5507 49.4767 42.384 52.7034 42.384H56.9128C58.2328 42.384 60.1249 42.2227 61.1662 40.8293C62.0609 39.6853 62.1342 38.0133 61.4155 35.5932Z"
            fill="white"
        />
        <path
            d="M57.3683 44.9951C56.987 44.5551 56.4296 44.3058 55.8576 44.3058H50.6069C48.0255 44.3058 45.9281 42.2084 45.9281 39.627V34.3763C45.9281 33.8042 45.6788 33.2469 45.2388 32.8656C44.8135 32.4842 44.2268 32.3082 43.6694 32.3816C40.2227 32.8216 37.0547 34.7136 34.9866 37.559C32.9039 40.419 32.1412 43.9097 32.8013 47.4005C33.7546 52.4459 37.788 56.4793 42.8481 57.4326C43.6548 57.594 44.4614 57.6673 45.2681 57.6673C47.9228 57.6673 50.4896 56.846 52.6749 55.2473C55.5203 53.1792 57.4123 50.0112 57.8523 46.5645C57.9257 45.9925 57.7497 45.4204 57.3683 44.9951Z"
            fill="white"
        />
    </>
);
