import classNames from 'classnames/bind';
import styles from './Modal.module.scss';
import Portal from '../common/Portal';
import { useDispatch, useSelector } from 'react-redux';
import { setPinModal, setUploadModal, setRenameModal } from '~/redux/modalSlice';

const cx = classNames.bind(styles);

function Modal({ children }) {
    const { pinModal, uploadModal, renameModal } = useSelector((state) => state.modal);
    const dispatch = useDispatch();

    const handleClose = () => {
        switch (true) {
            case pinModal:
                dispatch(setPinModal());
                break;
            case uploadModal:
                dispatch(setUploadModal());
                break;
            case renameModal:
                dispatch(setRenameModal());
                break;
            default:
                break;
        }
    };

    return (
        <Portal>
            <div className={cx('wrapper')}>
                <div className={cx('overlay')} onClick={handleClose} />
                <div className={cx('body')}>{children}</div>
            </div>
        </Portal>
    );
}

export default Modal;
