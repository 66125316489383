import classNames from 'classnames/bind';
import styles from './DefaultMenu.module.scss';
import images from '~/assets/images';
import { NavLink } from 'react-router-dom';

const cx = classNames.bind(styles);

function DefaultMenu({ data }) {
    return (
        <div className={cx('wrapper')}>
            <div className={cx('menu')}>
                {data.length > 0 &&
                    data.map((item, index) => (
                        <NavLink
                            to={item.to}
                            key={index}
                        >
                            {
                                ({ isActive }) => {
                                    return (
                                        <div className={cx('menu-item', { active: isActive })}>
                                            <div className={cx('icon')}>
                                                {isActive ? item.activeIcon : item.icon}
                                            </div>
                                            <p>{item.title}</p>
                                        </div>
                                    );
                                }
                            }
                        </NavLink>
                    ))}
            </div>
            <footer className={cx('footer')}>
                <span className={cx('background')} />
                <img src={images.illustration} alt={images.illustration} className={cx('image')} />
            </footer>
        </div>
    );
}

export default DefaultMenu;
