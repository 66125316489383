import classNames from 'classnames/bind';
import styles from './Register.module.scss';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { registerUser } from '~/api/services/authService';
import { useFormik } from 'formik';
import yupSchema from '~/utils/func/yupValidator';
import Input from '~/components/common/Input';
import Button from '~/components/common/Button/Button';
import { toast } from 'react-hot-toast';
import { publicPath } from '~/routes/path';

const cx = classNames.bind(styles);

function Register() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);

    const { values, errors, touched, isSubmitting, handleBlur, handleChange, handleSubmit } = useFormik({
        initialValues: {
            username: '',
            email: '',
            password: '',
            confirmPassword: '',
        },
        validationSchema: yupSchema(['email', 'username', 'password', 'confirmPassword']),
        onSubmit: async (values, actions) => {
            const registerPromise = new Promise(async (resolve, reject) => {
                try {
                    setLoading(true);
                    const newUser = {
                        username: values.username,
                        email: values.email,
                        password: values.password,
                    };
                    const result = await registerUser(newUser, dispatch, navigate);
                    if (!result) {
                        setError(true);
                        reject();
                    } else {
                        setSuccess(true);
                        resolve();
                    }
                } catch (e) {
                    console.log(e);
                    setError(true);
                    reject(e);
                } finally {
                    setLoading(false);
                }
            });

            await toast.promise(registerPromise, {
                loading: 'Registering...',
                success: 'Register successfully!',
                error: 'Register failed. Try again!',
            });
        },
    });

    return (
        <section className={cx('register-container')}>
            <h1 className={cx('register-title')}>Create Account</h1>

            <form onSubmit={handleSubmit} className={cx('form-wrapper')}>
                <Input
                    selector="username"
                    value={values.username}
                    touched={touched.username}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onError={errors.username}
                    className={cx('input-form')}
                />
                <Input
                    selector="email"
                    value={values.email}
                    touched={touched.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onError={errors.email}
                    className={cx('input-form')}
                />
                <Input
                    selector="password"
                    value={values.password}
                    touched={touched.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onError={errors.password}
                    className={cx('input-form')}
                />
                <Input
                    selector="confirmPassword"
                    value={values.confirmPassword}
                    touched={touched.confirmPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onError={errors.confirmPassword}
                    className={cx('input-form')}
                />

                {error && <p className={cx('register-failed')}>Register failed. Try again!</p>}

                <Button
                    type="submit"
                    className={cx('register-btn')}
                    disabled={loading || success || isSubmitting}
                    primary
                >
                    {loading ? 'Loading ...' : success ? 'Create Account Succsess !' : 'Create Account'}
                </Button>
            </form>

            <div className={cx('login-register')}>
                Already have an account?
                <Link className={cx('login-register-link')} to={publicPath.login}>
                    Login
                </Link>
            </div>
        </section>
    );
}

export default Register;
