import classNames from 'classnames/bind';
import styles from './MyPackage.module.scss';
import { Link } from 'react-router-dom';
import convertTimestamp, { convertTimestampToDateTime } from '~/utils/func/convertTimestamp';
import convertByte from '~/utils/func/convertByte';
import { renderPackageName } from '~/utils/data/priceData';
import { privatePath } from '~/routes/path';

const cx = classNames.bind(styles);

function MyPackage({ data, loading, error }) {
    const type = data ? renderPackageName(data.type) : '';
    const storage = data ? convertByte(data.storage) : '';
    const createdAt = data ? convertTimestamp(data.createdAt) : '';
    const period = data ? convertTimestampToDateTime(data.period) : '';

    return (
        <div className={cx('wrapper')}>
            <div className={cx('inner')}>
                <p className={cx('title')}>My Package</p>

                <div className={cx('description')}>
                    <div className={cx('info')}>
                        <span className={cx('info-title')}>Package Type :</span>
                        <span className={cx('info-data')}>{loading ? 'loading ...' : error ? 'error!' : type}</span>
                    </div>
                    <div className={cx('info')}>
                        <span className={cx('info-title')}>Available Storage :</span>
                        <span className={cx('info-data')}>{loading ? 'loading ...' : error ? 'error!' : storage}</span>
                    </div>
                    <div className={cx('info')}>
                        <span className={cx('info-title')}>Created At:</span>
                        <span className={cx('info-data')}>
                            {loading ? 'loading ...' : error ? 'error!' : createdAt}
                        </span>
                    </div>
                    <div className={cx('info')}>
                        <span className={cx('info-title')}>Expiry Date :</span>
                        <span className={cx('info-data')}>{loading ? 'loading ...' : error ? 'error!' : period}</span>
                    </div>
                    <Link className={cx('update-link')} to={privatePath.updatePackage}>
                        Do you want to update package now ?
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default MyPackage;
