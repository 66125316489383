import classNames from 'classnames/bind';
import styles from './UpdatePackage.module.scss';
import prices from '~/utils/data/priceData';

const cx = classNames.bind(styles);

function UpdatePackage() {
    return (
        <div className={cx('wrapper')}>
            {prices.map((price) => (
                <div className={cx('pricing-table', 'gprice-single')} key={price.type}>
                    <div className={cx('head')}>
                        <h4 className={cx('title')}>{price.name}</h4>
                    </div>
                    <div className={cx('content')}>
                        <div className={cx('price')}>
                            <h1 className={cx('$')}>${price.price}</h1>
                        </div>
                        <ul>
                            {price.features.map((feature, index) => (
                                <li key={index}>{feature.del ? <del>{feature.desc}</del> : feature.desc}</li>
                            ))}
                        </ul>
                        <div className={cx('sign-up')}>
                            <button className={cx('btn', 'bordered', 'radius')}>Signup Now</button>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default UpdatePackage;
