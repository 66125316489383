import {
    DashBroadIcon,
    DocumentIcon,
    ImageIcon,
    MediaIcon,
    OtherIcon,
    ActiveDashBroadIcon,
    ActiveDocumentIcon,
    ActiveImageIcon,
    ActiveMediaIcon,
    ActiveOtherIcon,
    AccountSettings,
    RecentFiles,
    // InviteAFriend,
    TrashBin,
    History,
    PushNotification,
} from '~/components/common/Icon/SubmenuIcons';
import { privatePath } from '~/routes/path';

export const defaultItemData = [
    { icon: <DashBroadIcon />, activeIcon: <ActiveDashBroadIcon />, title: 'Dashboard', to: privatePath.home },
    { icon: <DocumentIcon />, activeIcon: <ActiveDocumentIcon />, title: 'Documents', to: privatePath.document },
    { icon: <ImageIcon />, activeIcon: <ActiveImageIcon />, title: 'Images', to: privatePath.image },
    { icon: <MediaIcon />, activeIcon: <ActiveMediaIcon />, title: 'Video, Audio', to: privatePath.media },
    { icon: <OtherIcon />, activeIcon: <ActiveOtherIcon />, title: 'Others', to: privatePath.other },
];

export const moreItemData = [
    { icon: <AccountSettings />, title: 'Account Settings', to: privatePath.profile },
    { icon: <RecentFiles />, title: 'Update Package', to: privatePath.updatePackage },
    { icon: <History />, title: 'Recent Files', to: privatePath.recentFiles },
    // { icon: <InviteAFriend />, title: 'Invite a Friend', to: privatePath.inviteFriend },
    // { icon: <History />, title: 'History', to: privatePath.history },
    { icon: <TrashBin />, title: 'Trash Bin', to: privatePath.trash },
    { icon: <PushNotification />, title: 'Push Notification', notify: true },
];
