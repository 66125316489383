import axios from 'axios';
import queryString from 'query-string';
import { API_URL } from '~/constants';


const axiosClient = axios.create({
    baseURL: API_URL,
    headers: {
        'content-type': 'application/json',
    },
    paramsSerializer: params => queryString.stringify(params),
});
axiosClient.interceptors.request.use(async (config) => {
    // Handle token here ...
    const accessToken = JSON.parse(localStorage.getItem('accessToken'));
    if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
});
axiosClient.interceptors.response.use((response) => {
    if (response && response.data) {
    
        return response.data;
    }
    return response;
}, (error) => {
    let errorMessage = 'Something went wrong';

    if (error.response.data) {
        throw error.response.data;
    }
    throw errorMessage;
    // Handle errors

});
export default axiosClient;
