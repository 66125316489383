import { Link } from 'react-router-dom';
import classNames from 'classnames/bind';
import styles from './Button.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { setTheme } from '~/redux/toggleSlice';
// import { setIsConnectModal } from '~/redux/modalSlice/modalSlice';
import Switch from '../Switch';

const cx = classNames.bind(styles);

function Button({
    to,
    href,
    primary = false,
    outline = false,
    dark = false,
    borderline = false,
    iconOnly = false,
    text = false,
    rounded = false,
    disabled = false,
    large = false,
    medium = false,
    small = false,
    smallest = false,
    switchToggle = false,
    separate = false,
    connectModal = false,
    children,
    className,
    leftIcon,
    rightIcon,
    color,
    onClick,
    ...passProps
}) {
    let Comp = 'button';
    const props = {
        onClick,
        ...passProps,
    };

    if (to) {
        props.to = to;
        Comp = Link;
    } else if (href) {
        props.href = href;
        Comp = 'a';
    }

    // Remove event listener when btn is disabled
    if (disabled) {
        Object.keys(props).forEach((key) => {
            if (key.startsWith('on') && typeof props[key] === 'function') {
                delete props[key];
            }
        });
    }

    const classes = cx('wrapper', {
        [className]: className,
        primary,
        outline,
        iconOnly,
        dark,
        borderline,
        text,
        disabled,
        rounded,
        large,
        medium,
        small,
        smallest,
    });

    const darkMode = useSelector((state) => state.toggle.dark);
    const dispatch = useDispatch();

    const handleToggleTheme = () => {
        dispatch(setTheme());
    };

    return (
        <Comp className={classes} {...props}>
            {leftIcon && (
                <span className={cx('icon')} style={{ color: color }}>
                    {leftIcon}
                </span>
            )}
            <span className={cx('title')}>{children}</span>
            {rightIcon && (
                <span className={cx('icon')} style={{ color: color }}>
                    {rightIcon}
                </span>
            )}
            {switchToggle && <Switch mode={darkMode} onToggle={handleToggleTheme} className={cx('switch')} />}
            {separate && <span className={cx('separate')} />}
        </Comp>
    );
}

export default Button;
