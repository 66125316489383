import classNames from 'classnames/bind';
import styles from './OptionMenu.module.scss';
import Popover from '~/components/common/Popovers';
import Button from '~/components/common/Button';
import { BsCloudDownload, BsTrash3, BsInfoCircle } from 'react-icons/bs';
import { RxCounterClockwiseClock } from 'react-icons/rx';
import { IoPlayBackCircleOutline } from 'react-icons/io5';
import fyndrive from '~/api/services/fyndrive';
import axios from 'axios';
import { decryptFile, getCipherKey } from '~/service/encrypt';
import { useState } from 'react';
import PinModal from '~/components/Modal/PinModal';
import RenameModal from '~/components/Modal/RenameModal';
import ConfrimModal from '~/components/Modal/ConfrimModal';
import { toast } from 'react-hot-toast';
import { isBase64 } from '~/utils/func/checkBase64';
import { convertBase64ToBlob } from '~/utils/func/convertBase64ToBlob';

const cx = classNames.bind(styles);

function OptionMenu({ file, children, trash = false, refetchFiles }) {
    const [showPinModal, setShowPinModal] = useState(false);
    const [renameModal, setRenameModal] = useState(false);
    const [confrimModal, setConfrimModal] = useState(false);
    const [confrimPutBackModal, setConfrimPutBackModal] = useState(false);
    const [confrimDeleteModal, setConfrimDeteleModal] = useState(false);

    const handleDecryptThenDownload = async (pin) => {
        const downloadPromise = new Promise(async (resolve, reject) => {
            try {
                const downloadUrl = await fyndrive.downloadFile(file.key);
                const respBlob = await axios({
                    url: downloadUrl,
                    method: 'GET',
                    responseType: 'blob', // important
                });

                const respBase64 = await axios({
                    url: downloadUrl,
                    method: 'GET',
                });
                //check response is base64 or blob
                const isBase64File = isBase64(respBase64.data);
                const blob = isBase64File ? convertBase64ToBlob(respBase64.data) : new Blob([respBlob.data]);
                //get key
                const me = await fyndrive.me();
                const fileEncryptionKey = me.user.fileEncryptionKey;
                const cipherKey = await getCipherKey(pin, fileEncryptionKey);

                const decryptedFile = await decryptFile(cipherKey, blob);
                const url = window.URL.createObjectURL(new Blob([decryptedFile]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', file.name); //or any other extension
                document.body.appendChild(link);
                link.click();
                link.remove();
                resolve();
            } catch (e) {
                console.log(e);
                reject(e);
            } finally {
                setShowPinModal(false);
            }
        });
        await toast.promise(downloadPromise, {
            loading: 'Decrypting file...',
            success: 'File decrypted successfully',
            error: 'Error decrypting file',
        });
    };

    const menuItems = [
        {
            icon: <BsInfoCircle />,
            color: '#EEA8FD',
            rgba: 'rgba(238, 168, 253, 0.3)',
            title: 'Details',
            onClick: () => {},
        },
        {
            icon: <RxCounterClockwiseClock />,
            color: '#3DD9B3',
            rgba: 'rgba(61, 217, 179, 0.3)',
            title: 'Re-name',
            separate: true,
            onClick: () => setRenameModal(true),
        },
        {
            icon: <BsCloudDownload />,
            color: '#7288FA',
            rgba: 'rgba(114, 136, 250, 0.3)',
            title: 'Download',
            separate: true,
            onClick: () => setShowPinModal(true),
        },
        {
            icon: <BsTrash3 />,
            color: '#FF7474',
            rgba: 'rgba(255, 116, 116, 0.3)',
            title: 'Move to Trash',
            separate: true,
            onClick: () => setConfrimModal(true),
        },
    ];

    const menuTrashItems = [
        {
            icon: <IoPlayBackCircleOutline size={20} />,
            color: '#3DD9B3',
            rgba: 'rgba(61, 217, 179, 0.3)',
            title: 'Put Back',
            onClick: () => setConfrimPutBackModal(true),
        },
        {
            icon: <BsTrash3 />,
            color: '#FF7474',
            rgba: 'rgba(255, 116, 116, 0.3)',
            title: 'Delete',
            separate: true,
            onClick: () => setConfrimDeteleModal(true),
        },
    ];

    const renderResult = (attrs) => (
        <div>
            <div tabIndex="-1" {...attrs} className={cx('wrapper')}>
                <div className={cx('menu-popper')}>
                    <div className={cx('menu-header')}>
                        <p className={cx('header-title')}>{file?.name}</p>
                    </div>
                    <div className={cx('menu-body')}>
                        {trash
                            ? menuTrashItems.map((item, index) => (
                                  <Button
                                      className={cx('menu-item')}
                                      key={index}
                                      separate={item.separate}
                                      leftIcon={item.icon}
                                      color={item.color}
                                      onClick={item.onClick}
                                  >
                                      {item.title}
                                  </Button>
                              ))
                            : menuItems.map((item, index) => (
                                  <Button
                                      className={cx('menu-item')}
                                      key={index}
                                      separate={item.separate}
                                      leftIcon={item.icon}
                                      color={item.color}
                                      onClick={item.onClick}
                                  >
                                      {item.title}
                                  </Button>
                              ))}
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <>
            <Popover onRenderResult={renderResult} position={[-22, 5]}>
                {children}
            </Popover>
            {showPinModal && <PinModal onSuccess={handleDecryptThenDownload} onClose={() => setShowPinModal(false)} />}

            {renameModal && (
                <RenameModal
                    onClose={() => setRenameModal(false)}
                    _id={file?._id}
                    name={file?.name}
                    refetchFiles={refetchFiles}
                />
            )}
            {confrimModal && (
                <ConfrimModal
                    onClose={() => setConfrimModal(false)}
                    data={file}
                    moveTrash
                    refetchFiles={refetchFiles}
                />
            )}
            {confrimPutBackModal && (
                <ConfrimModal
                    onClose={() => setConfrimPutBackModal(false)}
                    data={file}
                    putBack
                    refetchFiles={refetchFiles}
                />
            )}
            {confrimDeleteModal && (
                <ConfrimModal
                    onClose={() => setConfrimDeteleModal(false)}
                    data={file}
                    remove
                    refetchFiles={refetchFiles}
                />
            )}
        </>
    );
}

export default OptionMenu;
