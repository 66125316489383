import aesjs from "aes-js";


const IV = process.env.REACT_APP_IV_KEY;
const sha256 = async (message) => {
    //hash the pin use sha256
    // encode as UTF-8
    const msgBuffer = new TextEncoder().encode(message);

    // hash the message
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);

    // convert ArrayBuffer to Array
    const hashArray = Array.from(new Uint8Array(hashBuffer));

    // convert bytes to hex string
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    return hashHex;
}

export const getCipherKey = async (PIN, encryptedKey) => {
    const hashedPin = await sha256(PIN);
    const hashedPinBytes = aesjs.utils.hex.toBytes(hashedPin);
    //use the hashed pin to decrypt the encrypted key using aes-256-cbc
    const ivBytes = aesjs.utils.hex.toBytes(IV);
    const aesCbc = new aesjs.ModeOfOperation.cbc(hashedPinBytes, ivBytes);

    //set block size to 256

    const encryptedBytes = aesjs.utils.hex.toBytes(encryptedKey);
    const decryptedBytes = aesCbc.decrypt(encryptedBytes);
    //we just need the first 32 bytes the rest is 101010 (non-sense)
    const cipherKey = decryptedBytes.slice(0, 32);
    return cipherKey;
}

export const encryptFile = async (cipherKey, file) => {
    if (!file) return;
    const fileContents = await readFileAsArrayBuffer(file);
    const encryptFileData = new Uint8Array(fileContents);

    const ivBytes = aesjs.utils.hex.toBytes(IV);

    // Create a new AES-CBC mode instance
    const aesCbc = new aesjs.ModeOfOperation.cbc(cipherKey, ivBytes);

    const paddedFileData = aesjs.padding.pkcs7.pad(encryptFileData);

    // Encrypt the input file contents
    const encryptedBytes = aesCbc.encrypt(paddedFileData);

    // Write the encrypted file to a new file
    const encryptedFile = new Blob([encryptedBytes], { type: file.type });
    return encryptedFile;
};

export const decryptFile = async (cipherKey, file) => {
    if (!file) return;

    const fileContents = await readFileAsArrayBuffer(file);
    const decryptedFileData = new Uint8Array(fileContents);

    const ivBytes = aesjs.utils.hex.toBytes(IV);

    // const key = generateKey(password);

    const aesCbc = new aesjs.ModeOfOperation.cbc(cipherKey, ivBytes);

    // Decrypt the encrypted file contents
    const decryptedBytes = aesCbc.decrypt(decryptedFileData);

    const unpaddedDecryptedBytes =
        aesjs.padding.pkcs7.strip(decryptedBytes);

    // Write the decrypted file to a new file
    const decryptedFile = new Blob([unpaddedDecryptedBytes], {
        type: file.type,
    });

    return decryptedFile;
};


const readFileAsArrayBuffer = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = () => reject(reader.error);
        reader.readAsArrayBuffer(file);
    });
};
