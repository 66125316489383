import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import { privateRoutes, publicRoutes } from '~/routes';
import ConnectLayout from './ConnectLayout/ConnectLayout';
import DefaultLayout from './DefaultLayout/DefaultLayout';
import { privatePath, publicPath } from '~/routes/path';
import { getUserDetail } from '~/api/services/authService';

const AppLayout = () => {
    const { currentUser } = useSelector((state) => state.auth.login);
    const token = localStorage.getItem('accessToken');
    const dispatch = useDispatch();

    useEffect(() => {
        if (!!token) {
            async function fetchUserInfo() {
                await getUserDetail(dispatch);
            }
            fetchUserInfo();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);

    return (
        <Routes>
            <Route element={<DefaultLayout />}>
                {privateRoutes.map((route) => (
                    <Route
                        key={route.path}
                        path={route.path}
                        element={currentUser || token ? <route.component /> : <Navigate to={publicPath.login} />}
                    />
                ))}
            </Route>
            <Route element={<ConnectLayout />}>
                {publicRoutes.map((route) => (
                    <Route
                        key={route.path}
                        path={route.path}
                        element={!currentUser && !token ? <route.component /> : <Navigate to={privatePath.home} />}
                    />
                ))}
            </Route>
        </Routes>
    );
};

export default AppLayout;
