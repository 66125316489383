import classNames from 'classnames/bind';
import styles from './Edit.module.scss';
import Input from '~/components/common/Input';
import { useFormik } from 'formik';
import yupSchema from '~/utils/func/yupValidator';
import Button from '~/components/common/Button/Button';

const cx = classNames.bind(styles);

function Edit({ fields, onChange }) {
    const { values, errors, touched, isSubmitting, handleBlur, handleChange, handleSubmit } = useFormik({
        initialValues: {
            username: '',
            fullname: '',
            phone: '',
            sex: '',
            location: '',
            city: '',
        },
        validationSchema: yupSchema(fields),
        onSubmit: async (values, actions) => {
            const filteredPairs = Object.entries(values).filter(([key, value]) => value.trim() !== '');
            if (filteredPairs.length > 0) {
                const filteredObject = filteredPairs.reduce((obj, [key, value]) => {
                    obj[key] = value;
                    return obj;
                }, {});
                console.log(filteredObject);
            }
            return;
        },
    });

    return (
        <form onSubmit={handleSubmit} className={cx('form-wrapper')}>
            <div className={cx('form-groups')}>
                <Input
                    selector="username"
                    value={values.username}
                    touched={touched.username}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onError={errors.username}
                />
                <Input
                    selector="location"
                    value={values.location}
                    touched={touched.location}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onError={errors.location}
                />
                <Input
                    selector="fullname"
                    value={values.fullname}
                    touched={touched.fullname}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onError={errors.fullname}
                />
                <Input
                    selector="city"
                    value={values.city}
                    touched={touched.city}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onError={errors.city}
                />
                <Input
                    selector="sex"
                    value={values.sex}
                    touched={touched.sex}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onError={errors.sex}
                />
                <Input
                    selector="phone"
                    value={values.phone}
                    touched={touched.phone}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onError={errors.phone}
                />
            </div>
            <div className={cx('btn-container')}>
                <Button className={cx('submit-btn')} type="submit" primary disabled={isSubmitting}>
                    Save
                </Button>
                <Button className={cx('submit-btn')} type="button" outline onClick={onChange}>
                    Cancel
                </Button>
            </div>
        </form>
    );
}

export default Edit;
