import Chart from 'react-apexcharts';

function MyChart({ percent, size, label }) {
    const chartData = {
        series: [{
            data: percent,
            name: label,
        }],
        options: {
            chart: {
                type: 'radialBar',
                toolbar: {
                    show: false,
                },
            },
            plotOptions: {
                radialBar: {
                    startAngle: -145,
                    endAngle: 145,
                    hollow: {
                        margin: 0,
                        size: '55%',
                        background: '#7288FA',
                        image: undefined,
                        imageOffsetX: 0,
                        imageOffsetY: 0,
                        position: 'front',
                        dropShadow: {
                            enabled: true,
                            top: 0,
                            left: 0,
                            blur: 0,
                            opacity: 0,
                        },
                    },
                    track: {
                        background: '#8e9ffc',
                        strokeWidth: '100%',
                        margin: 0, // margin is in pixels
                        dropShadow: {
                            enabled: true,
                            top: 0,
                            left: 0,
                            blur: 0,
                            opacity: 0.35,
                        },
                    },
                    dataLabels: {
                        show: true,
                        name: {
                            offsetY: 20,
                            show: true,
                            color: '#fff',
                            fontSize: '14px',
                        },
                        value: {
                            formatter: function ({data}) {
                                return data.toFixed(2) + '%';
                            },
                            color: '#FFF',
                            fontSize: '20px',
                            offsetY: -20,
                            show: true,
                        },
                    },
                },
            },
            fill: {
                colors: [
                    function ({ value, seriesIndex, w }) {
                        if (value < 55) {
                            return '#FFF';
                        } else if (value >= 70 && value < 90) {
                            return '#F9AB72';
                        } else {
                            return '#FE2C55';
                        }
                    },
                ],
            },
            stroke: {
                lineCap: 'round',
            },
            labels: [label],
        },
    };

    return <Chart options={chartData.options} series={chartData.series} type="radialBar" width={size} height={size} />;
}

export default MyChart;
