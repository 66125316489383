import classNames from 'classnames/bind';
import styles from './Switch.module.scss';

const cx = classNames.bind(styles);

function Switch({ onToggle, mode, className }) {
    const classes = cx('switch', mode && 'turn-on', {
        [className]: className,
    });

    return (
        <span className={classes} onClick={onToggle}>
            <p className={cx('switch-inner')} />
        </span>
    );
}

export default Switch;
