import classNames from 'classnames/bind';
import styles from './Input.module.scss';
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai';
import { useState } from 'react';

const cx = classNames.bind(styles);

function Input({ selector, value, placeholder, touched, onChange, onError, autoFocus, onBlur, onFocus, className }) {
    const [viewPass, setViewPass] = useState(false);

    const classes = cx('form-group', onError && touched && 'invalid', { [className]: className });
    return (
        <div className={classes}>


            <label className={cx('form-label')} htmlFor={selector}>
                {selector.charAt(0).toUpperCase()}
                {selector.slice(1)}
            </label>
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}>
                <input
                    className={cx('form-input')}
                    id={selector}
                    name={selector}
                    placeholder={placeholder ? placeholder : `Enter your ${selector}`}
                    type={(selector === 'password' || selector === 'confirmPassword') && !viewPass ? 'password' : 'text'}
                    autoComplete={selector === 'password' || selector === 'confirmPassword' ? 'on' : ''}
                    onChange={onChange}
                    value={value}
                    spellCheck={false}
                    autoFocus={autoFocus}
                    onBlur={onBlur}
                    onFocus={onFocus}
                />

                {
                    (selector === 'password' || selector === 'confirmPassword') && (
                        <div className={cx('eye')} onClick={() => setViewPass(!viewPass)}>
                            {viewPass ? <AiFillEye /> : <AiFillEyeInvisible />}
                        </div>
                    )
                }
            </div>
            {onError && touched && <p className={cx('form-message-error')}>{onError}</p>}
        </div>
    );
}

export default Input;
