import classNames from 'classnames/bind';
import styles from './HeaderActions.module.scss';
// import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { privatePath } from '~/routes/path';
import { logoutUser } from '~/api/services/authService';
import Avatar from '../common/Avatar';
import DropdownMenu from './DropdownMenu';
import { useDispatch, useSelector } from 'react-redux';
import { setMoreItems } from '~/redux/navigateSlice';
import { setPinModal } from '~/redux/modalSlice';
import checkLocation from '~/utils/func/checkLocation';
import { BiCloudUpload } from 'react-icons/bi';
import Button from '~/components/common/Button/Button';

const cx = classNames.bind(styles);

function HeaderActions() {
    const navigate = useNavigate();
    const location = useLocation();
    const isChecked = checkLocation(location.pathname);

    const dispatch = useDispatch();
    const { data } = useSelector((state) => state.auth.user);

    let myEmail;
    if (!!data) {
        myEmail = data?.user?.email;
    }

    const handleAction = (action) => {
        if (action.title === 'Profile') {
            dispatch(setMoreItems());
        } else if (action.title === 'Logout') {
            logoutUser(dispatch);
        }
    };

    const handleOpenModalPin = () => {
        //check if user have not file encryption key
        if (!data?.user?.fileEncryptionKey) {
            navigate(privatePath.getPin);
        }

        if (!isChecked) {
            navigate(privatePath.document);
        }

        dispatch(setPinModal());
    };

    return (
        <div className={cx('wrapper')}>
            {/* Upload btn */}

            <Button
                primary
                leftIcon={<BiCloudUpload size={28} className={cx('btn-icon')} />}
                className={cx('upload-btn')}
                onClick={handleOpenModalPin}
            >
                Upload
            </Button>

            {/* Usew */}
            <DropdownMenu onAction={handleAction}>
                <div className={cx('user')}>
                    <Avatar
                        medium
                        src="https://lh4.googleusercontent.com/ULcPJkJUZKKA8nbifBIj1Ne5oUQkfD_dcEpzQ76kP9OCQk9DlKg1iLmYJDjmcoaNG2-Gxd6ZMZyuWTt5P9oVTq6XK3xgsW0UqltaWLrOyl_xnWn-NH4lpS_rQ7mU5vfpIguvq3uzL4PklS0BBWulVr8"
                        alt="user-avatar"
                        className={cx('user-avatar')}
                    />
                    <p className={cx('user-name', 'hide-on-tablet')}>{myEmail}</p>
                </div>
            </DropdownMenu>
        </div>
    );
}

export default HeaderActions;
