import { createSlice } from '@reduxjs/toolkit';

const toggleSlice = createSlice({
    name: 'toggle',
    initialState: {
        dark: false,
        notify: true,
    },
    reducers: {
        setTheme: (state) => {
            state.dark = !state.dark;
        },
        setNotify: (state) => {
            state.notify = !state.notify;
        },
    },
});

export const { setTheme, setNotify } = toggleSlice.actions;

export default toggleSlice.reducer;
