import classNames from 'classnames/bind';
import styles from './File.module.scss';
import { CgMoreVertical } from 'react-icons/cg';
import OptionMenu from './OptionMenu';
import FilterIcon from '../common/FilterIcon';
import convertTimestamp from '~/utils/func/convertTimestamp';
import convertByte from '~/utils/func/convertByte';

const cx = classNames.bind(styles);

function FileItem({ file, grid, trash = false, refetchFiles }) {
    const classes = cx({
        gridStyle: grid,
        listStyle: !grid,
    });

    // console.log('FileItem >>>', file.type);

    return (
        <div className={classes}>
            <div className={cx('file-content')}>
                <div className={cx('file-icon')}>
                    <FilterIcon type={file.type} width={grid ? '46' : '32'} height={grid ? '50' : '36'} />
                </div>
                <div className={cx('file-desc')}>
                    <p className={cx('file-title')}>{file.name}</p>
                    <p className={cx('uploaded-time')}>{convertTimestamp(file?.createdAt)}</p>
                </div>
            </div>
            <div className={cx('sub-content')}>
                <div>
                    <OptionMenu file={file} trash={trash} refetchFiles={refetchFiles}>
                        <div className={cx('file-option')}>
                            <CgMoreVertical />
                        </div>
                    </OptionMenu>
                </div>
                <p className={cx('file-size')}>{convertByte(file.size)}</p>
            </div>
        </div>
    );
}

export default FileItem;
