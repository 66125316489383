export function convertTimestampToDateTime(timestamp) {
    var dateObj = new Date(timestamp * 1000); // Tạo đối tượng Date từ timestamp
    var hours = dateObj.getHours(); // Lấy giờ theo định dạng 24h (0-23)
    var minutes = dateObj.getMinutes(); // Lấy phút (0-59)
    var ampm = hours >= 12 ? 'pm' : 'am'; // Xác định AM hoặc PM
    hours = hours % 12 || 12; // Đổi sang định dạng 12 giờ và xử lý trường hợp 0 giờ thành 12 giờ
    minutes = minutes < 10 ? '0' + minutes : minutes; // Thêm số 0 trước phút nếu phút < 10
    var day = dateObj.getDate(); // Lấy ngày (1-31)
    var monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    var month = monthNames[dateObj.getMonth()]; // Lấy tên tháng (từ mảng monthNames)
    var year = dateObj.getFullYear(); // Lấy năm (4 số)
    var dateTime = hours + ':' + minutes + ' ' + ampm + ', ' + day + ' ' + month + ' ' + year; // Tạo chuỗi ngày và giờ
    return dateTime;
}

const convertTimestamp = (timestamp) => {
    const date = new Date(timestamp);

    const day = date.getDate().toString().padStart(2, '0');

    const monthIndex = date.getMonth();
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const month = months[monthIndex];

    const year = date.getFullYear();

    const hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const ampm = hours >= 12 ? 'pm' : 'am';
    const formattedHours = hours % 12 || 12;

    return `${formattedHours}:${minutes} ${ampm}, ${day} ${month} ${year}`;
};

export default convertTimestamp;
