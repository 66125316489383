import { Link } from 'react-router-dom';
import classNames from 'classnames/bind';
import styles from './Logo.module.scss';
import { privatePath } from '~/routes/path';

const cx = classNames.bind(styles);

function Logo({ to, textColor, connectLogo }) {
    const textClasses = cx('logo-title', { textColor });

    if (to) {
        return (
            <Link to={privatePath.home} className={cx('wrapper')}>
                <svg width="53" height="52" viewBox="0 0 53 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="48" height="48" rx="24" fill={connectLogo ? 'white' : '#CAD2FF'} />
                    <rect x="21" y="20" width="32" height="32" rx="16" fill={connectLogo ? 'white' : '#7288FA'} />
                </svg>
                <p className={cx('logo-title')}>Fyndrive</p>
            </Link>
        );
    } else {
        return (
            <div className={cx('wrapper')}>
                <svg
                    width={connectLogo ? '82' : '53'}
                    height={connectLogo ? '81' : '52'}
                    viewBox="0 0 53 52"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect
                        opacity={connectLogo ? '0.2' : ''}
                        width="48"
                        height="48"
                        rx="24"
                        fill={connectLogo ? 'white' : '#CAD2FF'}
                    />
                    <rect x="21" y="20" width="32" height="32" rx="16" fill={connectLogo ? 'white' : '#7288FA'} />
                </svg>
                <p className={textClasses}>Fyndrive</p>
            </div>
        );
    }
}

export default Logo;
