import axiosClient from './axiosClient';
const fyndrive = {
    getRecentFiles: async (options) => {
        const url = '/file';
        return await axiosClient.get(url, {
            params: options,
        });
    },

    getFilesByType: async (type, options) => {
        const url = '/file';
        const types = ['Images', 'Medias', 'Documents', 'Others', 'Trash'];
        const urlTypes = ['images', 'medias', 'documents', 'others', 'trash'];
        const index = urlTypes.indexOf(type) || 0;
        return await axiosClient.get(url, {
            params: {
                folder: types[index],
                ...options,
            },
        });
    },

    me: async () => {
        const url = '/account/me';
        return await axiosClient.get(url);
    },

    prepareEncryptionKey: async (pin) => {
        const url = '/cypher/prepare-key';
        return await axiosClient.post(url, {
            PIN: pin,
        });
    },
    checkEncryptionKey(pin) {
        const url = '/cypher/check-pin';
        return axiosClient.post(url, {
            PIN: pin,
        });
    },

    getSignedUrl: async (data) => {
        const url = '/drive/upload';
        return await axiosClient.post(url, data);
    },

    downloadFile: async (fileKey) => {
        const url = `/drive/download/${fileKey}`;
        return await axiosClient.get(url);
    },
};

export default fyndrive;
