import Tippy from '@tippyjs/react/headless';

function Popover({ children, onRenderResult, position = [0, 0], placement = 'bottom', hideOnClick = false }) {
    return (
        <Tippy
            interactive
            // visible
            delay={[500, 50]}
            offset={position}
            placement={placement}
            render={onRenderResult}
            hideOnClick={hideOnClick}
        >
            {children}
        </Tippy>
    );
}

export default Popover;
