/* eslint-disable no-useless-escape */
import * as Yup from 'yup';

const yupSchema = (fields) => {
    const schema = {
        email: Yup.string()
            .required('Required')
            .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, 'Please enter a valid email address'),
        password: Yup.string()
            .required('Required')
            .min(6, 'Must be 6 characters or more')
            .max(14, 'Must not over 14 characters'),
        // .matches(
        //     /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d][A-Za-z\d!@#$%^&*()_+]{6,12}$/,
        //     'Password must be 7-12 characters and contain at least one letter, one number and a special character',
        // ),
        confirmPassword: Yup.string()
            .required('Required')
            .oneOf([Yup.ref('password'), null], 'Password must match'),
        username: Yup.string()
            // .required('Required')
            .min(4, 'Must be 4 characters or more')
            .max(14, 'Must not over 14 characters'),
        phone: Yup.string().matches(
            /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
            'Must be a valid phone number',
        ),
    };

    let validate = {};
    fields.forEach((field) => {
        validate[field] = schema[field];
    });

    return Yup.object().shape(validate);
};

export default yupSchema;
