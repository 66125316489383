import classNames from 'classnames/bind';
import { forwardRef, useState } from 'react';
import images from '~/assets/images';
import styles from './Avatar.module.scss';

const cx = classNames.bind(styles);
const Avatar = forwardRef(
    ({ src, alt, small, medium, large, className, fallback: customFallback = images.noImage, ...props }, ref) => {
        const [fallback, setFallback] = useState('');

        const classes = cx('wrapper', {
            [className]: className,
            small,
            medium,
            large,
        });

        const handleError = () => {
            setFallback(customFallback);
        };

        return <img className={classes} ref={ref} src={fallback || src} alt={alt} {...props} onError={handleError} />;
    },
);

export default Avatar;
