import classNames from 'classnames/bind';
import styles from './Profile.module.scss';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import Info from './Info';
import Edit from './Edit';

const cx = classNames.bind(styles);

function Profile() {
    const { data } = useSelector((state) => state.auth.user);
    const [myInfo, setMyInfo] = useState({});
    const [isEdit, setIsEdit] = useState(false);
    const profileFields = ['username', 'location', 'fullname', 'city', 'sex', 'phone'];

    useEffect(() => {
        if (data !== null) {
            setMyInfo(data.user);
        }
    }, [data]);

    return (
        <section className={cx('wrapper')}>
            <p className={cx('header-title')}>Account Settings</p>
            {isEdit ? (
                <Edit data={myInfo} fields={profileFields} cancel={isEdit} onChange={() => setIsEdit(!isEdit)} />
            ) : (
                <Info data={myInfo} fields={profileFields} onChange={() => setIsEdit(!isEdit)} />
            )}
        </section>
    );
}

export default Profile;
