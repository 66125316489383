import { configureStore } from '@reduxjs/toolkit';
import authSlice from './authSlice';
import toggleSlice from './toggleSlice';
import navigateSlice from './navigateSlice';
import modalSlice from './modalSlice';

const rootReducer = {
    auth: authSlice,
    navigate: navigateSlice,
    toggle: toggleSlice,
    modal: modalSlice,
};
const store = configureStore({
    reducer: rootReducer,
});

export default store;
