const prices = [
    {
        name: 'Free',
        type: 0,
        period: '1Year',
        status: 'isActive',
        price: 0,
        storage: '10GB',
        features: [
            { desc: '10GB Ram' },
            { desc: 'Exp: 1 Year' },
            { desc: 'Month Subscription' },
            { desc: 'Responsive Framework' },
            { desc: 'Monthly Billing Software', del: true },
            { desc: '1 Free Website', del: true },
        ],
    },
    {
        name: 'Plus',
        type: 1,
        period: '1Year6Month',
        status: 'isActive',
        price: 10,
        storage: '2000GB',
        features: [
            { desc: '2000GB Ram' },
            { desc: 'Exp: 1 Year 6 Month' },
            { desc: 'Month Subscription' },
            { desc: 'Responsive Framework' },
            { desc: 'Monthly Billing Software', del: true },
            { desc: '1 Free Website', del: true },
        ],
    },
    {
        name: 'Pro',
        type: 2,
        period: '2Year',
        status: 'isActive',
        price: 20,
        storage: '3000GB',
        features: [
            { desc: '3000GB Ram' },
            { desc: 'Exp: 2 Year' },
            { desc: 'Month Subscription' },
            { desc: 'Responsive Framework' },
            { desc: 'Monthly Billing Software', del: true },
            { desc: '1 Free Website', del: true },
        ],
    },
    {
        name: 'Enterprise',
        type: 3,
        period: '3Year',
        status: 'isActive',
        price: 30,
        storage: '5000GB',
        features: [
            { desc: '5000GB Ram' },
            { desc: 'Exp: 3 Year' },
            { desc: 'Month Subscription' },
            { desc: 'Responsive Framework' },
            { desc: 'Monthly Billing Software', del: true },
            { desc: '1 Free Website', del: true },
        ],
    },
];

export const renderPackageName = (type) => {
    return prices.filter((price) => {
        return price.type === type;
    })[0].name;
};

export default prices;
