import classNames from 'classnames/bind';
import styles from './SubMenu.module.scss';
import { NavLink, useNavigate } from 'react-router-dom';
import { privatePath } from '~/routes/path';
import { useDispatch, useSelector } from 'react-redux';
import { setDefaultItems } from '~/redux/navigateSlice';
import { setNotify } from '~/redux/toggleSlice';
import { logoutUser } from '~/api/services/authService';
import { IoArrowBackSharp } from 'react-icons/io5';
import { CgLogOut } from 'react-icons/cg';
import Switch from '~/components/common/Switch';

const cx = classNames.bind(styles);

function SubMenu({ data }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const notifyMode = useSelector((state) => state.toggle.notify);

    const handleBack = () => {
        dispatch(setDefaultItems());
        navigate(privatePath.home);
    };

    const handleLogout = () => {
        logoutUser(dispatch, navigate);
    };

    const handleToggle = () => {
        dispatch(setNotify());
        console.log('push notifi');
    };

    return (
        <div className={cx('wrapper')}>
            <div className={cx('menu-main')}>
                <div className={cx('menu-header')}>
                    <button className={cx('back-btn')} onClick={handleBack}>
                        {<IoArrowBackSharp size={21} />}
                    </button>
                    <p className={cx('header-title')}>Back to Dashboard</p>
                </div>
                <div className={cx('menu-body')}>
                    {data.length > 0 &&
                        data.map((item, index) => {
                            if (item.notify) {
                                return (
                                    <div className={cx('menu-item')} key={index}>
                                        <div className={cx('icon')}>{item.icon}</div>
                                        <p className={cx('title')}>{item.title}</p>
                                        <Switch mode={notifyMode} onToggle={handleToggle} className={cx('switch')} />
                                    </div>
                                );
                            }
                            return (
                                <NavLink
                                    className={(nav) => cx('menu-item', { active: nav.isActive })}
                                    to={item.to}
                                    key={index}
                                >
                                    <div className={cx('icon')}>{item.icon}</div>
                                    <p className={cx('title')}>{item.title}</p>
                                </NavLink>
                            );
                        })}
                </div>
            </div>
            <div className={cx('menu-footer')}>
                <button className={cx('logout-btn')} onClick={handleLogout}>
                    <CgLogOut size={20} />
                    <span className={cx('logout-title')}>Logout</span>
                </button>
            </div>
        </div>
    );
}

export default SubMenu;
