const privatePath = {
    home: '/',
    document: '/documents',
    image: '/images',
    media: '/medias',
    other: '/others',
    profile: '/profile',
    recentFiles: '/recent-files',
    inviteFriend: '/invite-friend',
    trash: '/trash',
    history: '/history',
    updatePackage: '/update-package',
    getPin: '/get-pin',
};

const publicPath = {
    login: '/login',
    register: '/register',
    forgotPassword: '/forgot-password',
    verify: '/verify-account',
    resetPassword: '/reset/password/:token',
};

export { privatePath, publicPath };
