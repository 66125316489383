import classNames from 'classnames/bind';
import styles from './ConfrimModal.module.scss';
import Modal from '../Modal';
import Button from '~/components/common/Button';
import { moveToTrash, putBackPrevFolder, removeFile } from '~/api/services/fileService';
import { MdOutlineClear } from 'react-icons/md';
import { useState } from 'react';

const cx = classNames.bind(styles);

function ConfrimModal({ data, moveTrash, putBack, remove, onClose, refetchFiles }) {
    const [error, setError] = useState(false);

    const handleMoveToTrash = async (id) => {
        const move = await moveToTrash(id);
        if (!move) {
            setError(true);
        }
        await refetchFiles();
        onClose();
    };

    const handlePutBack = async (id) => {
        const putBack = await putBackPrevFolder(id);
        if (!putBack) {
            setError(true);
        }
        await refetchFiles();
        onClose();
    };

    const handleRemove = async (key) => {
        const remove = await removeFile(key);
        if (!remove) {
            setError(false);
        }
        await refetchFiles();
        onClose();
    };

    return (
        <Modal onClose={onClose}>
            <div className={cx('wrapper')}>
                <div className={cx('header')}>
                    <p className={cx('title')}>
                        {moveTrash && <span>Confrim Move To Trash</span>}
                        {putBack && <span>Confrim Put Back</span>}
                        {remove && <span>Confrim Remove File</span>}
                    </p>
                    <MdOutlineClear className={cx('close')} onClick={onClose} />
                </div>
                <p className={cx('description')}>
                    {moveTrash && (
                        <span>
                            Are you sure you want move <span className={cx('name')}>{data.name}</span> to Trash ?
                        </span>
                    )}
                    {putBack && (
                        <span>
                            Are you sure you want put <span className={cx('name')}>{data.name}</span> to back folder{' '}
                            {data.prevFolder} ?
                        </span>
                    )}
                    {remove && (
                        <span>
                            Are you sure you want remove <span className={cx('name-remove')}>{data.name}</span> ?
                        </span>
                    )}
                </p>

                {error && <p className={cx('error')}>Error</p>}

                <div className={cx('selection')}>
                    <Button className={cx('cancel-btn')} onClick={onClose} outline>
                        Cancel
                    </Button>
                    {moveTrash && (
                        <Button className={cx('confrim-btn')} primary onClick={() => handleMoveToTrash(data._id)}>
                            Move to Trash
                        </Button>
                    )}
                    {putBack && (
                        <Button className={cx('confrim-btn')} primary onClick={() => handlePutBack(data._id)}>
                            Put Back
                        </Button>
                    )}
                    {remove && (
                        <Button
                            className={cx('confrim-btn', remove && 'remove-btn')}
                            primary
                            onClick={() => handleRemove(data.key)}
                        >
                            Remove
                        </Button>
                    )}
                </div>
            </div>
        </Modal>
    );
}

export default ConfrimModal;
