import classNames from 'classnames/bind';
import styles from './Login.module.scss';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { loginUser } from '~/api/services/authService';
import { useFormik } from 'formik';
import yupSchema from '~/utils/func/yupValidator';
import Input from '~/components/common/Input';
import Button from '~/components/common/Button/Button';
import { toast } from 'react-hot-toast';
import { publicPath } from '~/routes/path';

const cx = classNames.bind(styles);

function Login() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);

    const { values, errors, touched, isSubmitting, handleBlur, handleChange, handleSubmit } = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: yupSchema(['email']),
        onSubmit: async (values, actions) => {
            const loginPromise = new Promise(async (resolve, reject) => {
                try {
                    setLoading(true);
                    const accessUser = {
                        email: values.email.trim(),
                        password: values.password.trim(),
                    };

                    const loginResult = await loginUser(accessUser, dispatch, navigate);

                    if (!loginResult) {
                        setError(true);
                        reject();
                    } else {
                        setSuccess(true);
                        resolve();
                    }
                } catch (e) {
                    console.log(e);
                    setError(true);
                    reject(e);
                } finally {
                    setLoading(false);
                }
            });
            await toast.promise(loginPromise, {
                loading: 'Loading',
                success: 'Login Success',
                error: 'Login Failed',
            });
        },
    });

    return (
        <section className={cx('login-container')}>
            <h1 className={cx('login-title')}>Login</h1>

            <form onSubmit={handleSubmit} className={cx('form-wrapper')}>
                <Input
                    autoFocus
                    selector="email"
                    value={values.email}
                    touched={touched.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onError={errors.email}
                    className={cx('input-form')}
                />
                <Input
                    selector="password"
                    value={values.password}
                    touched={touched.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onError={errors.password}
                />

                <div className={cx('sub-actions')}>
                    <Link to={publicPath.forgotPassword} className={cx('forgot-password')}>
                        Forgot password ?
                    </Link>
                </div>
                {error && <p className={cx('login-failed')}>Login failed. Try again!</p>}
                <Button type="submit" className={cx('login-btn')} disabled={loading || success || isSubmitting} primary>
                    {loading ? 'Loading ...' : success ? 'Login succsess !' : 'Login'}
                </Button>
            </form>

            <div className={cx('to_create_account')}>
                <div className={cx('login-register')}>
                    Don't have an account ?
                    <Link className={cx('login-register-link')} to="/register">
                        Create Account
                    </Link>
                </div>
            </div>
        </section>
    );
}

export default Login;
