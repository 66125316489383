import classNames from 'classnames/bind';
import styles from './Pin.module.scss';
import { useState } from 'react';
import OtpInput from '~/components/Modal/PinModal/OtpInput';
import Button from '~/components/common/Button/Button';
import fyndrive from '~/api/services/fyndrive';
import { toast } from 'react-hot-toast';

const cx = classNames.bind(styles);
function Pin() {
    const [pin, setPin] = useState('');
    const onChange = (value) => setPin(value);
    const [loading, setLoading] = useState(false);

    const handleConfirm = async () => {
        try {
            setLoading(true);

            const res = await fyndrive.prepareEncryptionKey(pin);
            if (!res) {
                toast.error('Get PIN failed');
            }

            toast.success('PIN set successfully');
        } catch (e) {
            console.log(e);
            toast.error(e.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className={cx('wrapper')}>
            <div className={cx('header')}>
                <p className={cx('header-title')}>My PIN CODE</p>
                <p className={cx('header-desc')}>
                    Please enter your PIN code to manage files (add, edit, delete, etc.).
                    <br />
                    Your PIN code will be required to confirm any file processing actions.
                    <br />
                    After registration, the PIN code will be encrypted one-way, so please remember it and avoid letting
                    others know.
                    <br />
                </p>
            </div>
            <div className={cx('pin-container')}>
                <div className={cx('pin-inner')}>
                    <OtpInput value={pin} valueLength={6} onChange={onChange} />

                    <Button primary className={cx('confirm-btn')} onClick={handleConfirm} disabled={loading}>
                        {loading ? 'Confirming...' : 'Confirm'}
                    </Button>
                </div>
            </div>
            <div className={cx('footer')}>
                <p className={cx('sub-desc')}>
                    <strong>Note:</strong> We cannot provide you with your PIN code in case you forget it or for any
                    reason. Therefore, please keep your registered PIN code safe.
                    <br />
                    We can only provide a service to change the PIN code upon your request, provided that you still
                    remember the old PIN code.
                    <br />
                    Thank you.
                </p>
            </div>
        </div>
    );
}

export default Pin;
