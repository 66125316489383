import { createSlice } from '@reduxjs/toolkit';

const modalSlice = createSlice({
    name: 'modal',
    initialState: {
        pinModal: false,
        uploadModal: false,
        renameModal: false,
    },
    reducers: {
        setPinModal: (state) => {
            state.pinModal = !state.pinModal;
        },
        setUploadModal: (state) => {
            state.uploadModal = !state.uploadModal;
        },
        setRenameModal: (state) => {
            state.renameModal = !state.renameModal;
        },
    },
});

export const { setPinModal, setUploadModal, setRenameModal } = modalSlice.actions;

export default modalSlice.reducer;
