import classNames from 'classnames/bind';
import styles from './Folder.module.scss';
import { Link, useNavigate } from 'react-router-dom';
import convertTimestamp from '~/utils/func/convertTimestamp';
import convertByte from '~/utils/func/convertByte';

const cx = classNames.bind(styles);

function FolderItem({ icon, storage, title, time, to }) {
    const navigate = useNavigate();
    return (
        <div className={cx('folder-item')} onClick={() => navigate(`${to}`)}>
            <div className={cx('header')}>
                {icon}
                <div className={cx('amount')}>{storage > 0 ? convertByte(storage) : '0'}</div>
            </div>
            <div className={cx('title')}>{title}</div>
            <div className={cx('line')} />
            <div className={cx('update')}>Last update</div>
            <div className={cx('update-time')}>
                {time > 0 ? convertTimestamp(time) : ''}
            </div>
        </div >
    );
}

export default FolderItem;
