import { createSlice } from '@reduxjs/toolkit';

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        login: {
            isFetching: false,
            currentUser: null,
            error: false,
        },
        register: {
            isFetching: false,
            success: false,
            error: false,
        },
        user: {
            isFetching: false,
            data: null,
            error: false,
        },
    },
    reducers: {
        //LOGIN REDUCER
        loginStart: (state) => {
            state.login.isFetching = true;
        },
        loginSuccess: (state, action) => {
            state.login.isFetching = false;
            state.login.currentUser = action.payload;
            state.login.error = false;
        },
        loginFailed: (state) => {
            state.login.isFetching = false;
            state.login.error = true;
        },
        //LOGOUT REDUCER
        logOutStart: (state) => {
            state.login.isFetching = true;
        },
        logOutSuccess: (state) => {
            state.login.isFetching = false;
            state.login.currentUser = null;
            state.login.error = false;
        },
        logOutFailed: (state) => {
            state.login.isFetching = false;
            state.login.error = true;
        },
        //REGISTER REDUCER
        registerStart: (state) => {
            state.register.isFetching = true;
        },
        registerSuccess: (state, action) => {
            state.register.isFetching = false;
            state.register.success = true;
            state.register.error = false;
        },
        registerFailed: (state) => {
            state.register.isFetching = false;
            state.register.success = false;
            state.register.error = true;
        },
        //USER INFO
        getMyInfoStart: (state) => {
            state.user.isFetching = true;
        },
        getMyInfoSuccess: (state, action) => {
            state.user.isFetching = false;
            state.user.data = action.payload;
            state.user.error = false;
        },
        getMyInfoFailed: (state) => {
            state.user.isFetching = false;
            state.user.error = true;
        },
    },
});

export const {
    loginStart,
    loginSuccess,
    loginFailed,
    logOutStart,
    logOutSuccess,
    logOutFailed,
    registerStart,
    registerSuccess,
    registerFailed,
    getMyInfoStart,
    getMyInfoSuccess,
    getMyInfoFailed,
} = authSlice.actions;

export default authSlice.reducer;
