import styles from './ForgotPassword.module.scss';
import classNames from 'classnames/bind';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { sendMailWhenForgotPass } from '~/api/services/authService';
import Input from '~/components/common/Input';
import Button from '~/components/common/Button';
import { useFormik } from 'formik';
import yupSchema from '~/utils/func/yupValidator';

const cx = classNames.bind(styles);

function ForgotPassword() {
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);

    const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: yupSchema(['email']),
        onSubmit: async (values, actions) => {
            const sendEmailPromise = new Promise(async (resolve, reject) => {
                try {
                    setLoading(true);
                    setError(false);

                    const sendData = {
                        email: values.email,
                    };

                    const sendEmailResult = await sendMailWhenForgotPass(sendData);

                    if (!sendEmailResult) {
                        setError(true);
                        reject();
                    } else {
                        actions.resetForm();
                        setSuccess(true);
                        resolve();
                    }
                } catch (e) {
                    console.log(e);
                    setError(true);
                    reject(e);
                } finally {
                    setLoading(false);
                }
            });
            await toast.promise(sendEmailPromise, {
                loading: 'Loading',
                success: 'Send Mail Success',
                error: 'Send Mail Failed',
            });
        },
    });

    return (
        <section className={cx('wrapper')}>
            <div className={cx('header')}>
                <h1 className={cx('header-title')}>Forgot your password?</h1>
                <p className={cx('header-sub-title')}>
                    Enter your email address and we will send you instructions on how to reset your password.
                </p>
            </div>
            <form onSubmit={handleSubmit} className={cx('form-wrapper')}>
                <Input
                    selector="email"
                    value={values.email}
                    touched={touched.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onError={errors.email}
                />

                {error && (
                    <p className={cx('error')}>Error: Can't recover email. Let confirm your email and try agian !</p>
                )}
                <Button type="submit" className={cx('submit-btn')} disabled={loading || success}>
                    {loading
                        ? 'Sending ...'
                        : success
                        ? 'Sent success. Let check your mail to reset password !'
                        : 'Recover Email'}
                </Button>
            </form>

            <div className={cx('footer')}>
                <div className={cx('login-register')}>
                    Don't have an account ?
                    <Link className={cx('login-register-link')} to="/register">
                        Create Account
                    </Link>
                </div>
            </div>
        </section>
    );
}
export default ForgotPassword;
