const getFolderNameByFileType = (fileType) => {
    switch (fileType.split('/')[0]) {
        case 'image':
            return 'Images';
        case 'video':
            return 'Medias';
        case 'audio':
            return 'Medias';
        case 'text':
            return 'Documents';
        case 'application':
            return 'Documents';
        default:
            return 'Others';
    }
};

export default getFolderNameByFileType;
