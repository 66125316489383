import { privatePath, publicPath } from './path';
import Home from '~/pages/Home';
import Folder from '~/pages/Folder';
import Profile from '~/pages/Profile';
import Invite from '~/pages/Invite';
import History from '~/pages/History';
import UpdatePackage from '~/pages/UpdatePackage';
import Pin from '~/pages/Pin';
import Login from '~/pages/Login';
import Register from '~/pages/Register';
import Verify from '~/pages/Verify';
import ForgotPassword from '~/pages/ForgotPassword';
import ResetPassword from '~/pages/ResetPassword';

export const publicRoutes = [
    //public
    { path: publicPath.login, component: Login },
    { path: publicPath.register, component: Register },
    { path: publicPath.verify, component: Verify },
    { path: publicPath.forgotPassword, component: ForgotPassword },
    { path: publicPath.resetPassword, component: ResetPassword },
];

export const privateRoutes = [
    // private
    { path: privatePath.home, component: Home },
    { path: privatePath.document, component: Folder },
    { path: privatePath.image, component: Folder },
    { path: privatePath.media, component: Folder },
    { path: privatePath.other, component: Folder },
    { path: privatePath.profile, component: Profile },
    { path: privatePath.recentFiles, component: Folder },
    { path: privatePath.inviteFriend, component: Invite },
    { path: privatePath.trash, component: Folder },
    { path: privatePath.history, component: History },
    { path: privatePath.updatePackage, component: UpdatePackage },
    { path: privatePath.getPin, component: Pin },
];
