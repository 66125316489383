import classNames from 'classnames/bind';
import styles from './DropdownMenu.module.scss';
import Popover from '~/components/common/Popovers';
import Button from '~/components/common/Button';
import { privatePath } from '~/routes/path';

const cx = classNames.bind(styles);

const menuItems = [
    { title: 'Profile', to: privatePath.profile },
    { title: 'View mode', separate: true, switch: true },
    { title: 'Logout', separate: true },
];

function DropdownMenu({ children, onAction }) {
    const renderResult = (attrs) => (
        <div tabIndex="-1" {...attrs} className={cx('wrapper')}>
            <div className={cx('menu-popper')}>
                <div className={cx('menu-body')}>
                    {menuItems.map((item, index) => (
                        <Button
                            className={cx('menu-item')}
                            key={index}
                            to={item.to}
                            switchToggle={item.switch}
                            separate={item.separate}
                            onClick={() => onAction(item)}
                        >
                            {item.title}
                        </Button>
                    ))}
                </div>
            </div>
        </div>
    );

    return (
        <Popover onRenderResult={renderResult} position={[-22, 5]}>
            {children}
        </Popover>
    );
}

export default DropdownMenu;
