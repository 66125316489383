import { privatePath } from '~/routes/path';
const checkLocation = (location) => {
    const uploadFilePaths = [privatePath.document, privatePath.image, privatePath.media, privatePath.other];

    const check = uploadFilePaths.some((path) => path === location);

    return check;
};

export default checkLocation;
