import { getRequest } from '../config';

export const searchRequest = async (q) => {
    try {
        const res = await getRequest('file/search', {
            params: {
                q,
            },
        });

        return res;
    } catch (error) {
        console.log(error);
    }
};
